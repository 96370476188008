import React from 'react';

import {
  openSite,
} from '../../../../config/linking.config';

import {
  locationCountryArray,
} from '../../../../shared/arrays/location-country.array';

import {
  placesProArray,
} from '../../../../shared/arrays/partners.array';

import Vars from '../../../../shared/environments/variables';

import {
  UseProContainer,
  UseProTitle,
  UseProSubtitle,
  UseProCompanyCard,
  UseProImageCompanyView,
  UseProCompanyImage,
  UseProImageFlagView,
  UseProImageFlagImage,
  UseProCompanyTitle,
  UseProFooterTitle,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionProWhoUse: React.FC<IProps> = (props: IProps) => {
  // Embaralhar o array
  const shuffledArray = [...placesProArray].sort(() => Math.random() - 0.5);

  // Selecionar os primeiros 8 itens ou todos os itens se o array for menor que 8
  const selectedCompanies = shuffledArray.slice(0, Math.min(shuffledArray.length, 8));



  function onOpenProfile(idShort: string) {
    openSite(`${Vars().appNoitadaSite}/${idShort}`);
  }


  function renderItem(item: any, index: number) {
    // Busca pelo país correspondente usando o código do país
    const countryInfo = locationCountryArray.find((country) => country.code === item?.country);


    return (

      <UseProCompanyCard
        key={index}
        onClick={() => {
          if (item?.idShort) {
            onOpenProfile(item?.idShort);
          }
        }}>

        <UseProImageCompanyView>

          <UseProCompanyImage
            src={item?.image}
          />


          <UseProImageFlagView>

            <UseProImageFlagImage
              src={countryInfo?.flagImage}
            />

          </UseProImageFlagView>

        </UseProImageCompanyView>



        <UseProCompanyTitle>
          {item?.name || '-'}
        </UseProCompanyTitle>

      </UseProCompanyCard>

    );
  }



  return (

    <UseProContainer
      id={props.id}>

      <UseProTitle>
        {'Quem usa o PRO da Noitada'.toUpperCase()}
      </UseProTitle>



      <UseProSubtitle>
        {selectedCompanies.map((item, index: number) => (
          renderItem(item, index)
        ))}
      </UseProSubtitle>



      <UseProFooterTitle>
        {'E muito mais'.toUpperCase()}
      </UseProFooterTitle>

    </UseProContainer>

  );
};



export default SectionProWhoUse;
