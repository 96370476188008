import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  ContactSectionContainer,
  ContactSectionTitle,
  ContactSectionSubtitle,
} from './styled';



export interface IProps {
  id?: string;

  buttonText?: string;
  buttonPress?: any;
}



const ContainerContact: React.FC<IProps> = (props) => {
  const history = useHistory();



  return (

    <ContactSectionContainer
      id={props.id}>

      <ContactSectionTitle>
        {'Entre em contato'.toUpperCase()}
      </ContactSectionTitle>



      <ContactSectionSubtitle>
        <ButtonAccess
          text={'Contato'}
          onPress={() => {
            history.push(NameRoutes.ContactScreen);
          }}
        />
      </ContactSectionSubtitle>

    </ContactSectionContainer>

  );
};



export default ContainerContact;
