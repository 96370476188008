import React from 'react';
import './styled.css';

import Error500Image from '../../shared/assets/images/error_500.png';



function Error500Screen() {
  return (

    <body className="body-container error-container error500-container" >
      <div className="exception-body-container">
        <div className="exception-container">
          <img src="https://www.primefaces.org/apollo-react/assets/layout/images/logo-dark.png" alt="apollo-layout" />
          <h1>Error 500</h1>
          <p>
            Desculpe, o servidor está reportando um erro.
          </p>
          <a href="/" className="btn-to-click">Volte para o início</a>
        </div>
        <div className="exception-body-image">
          <img src={Error500Image} alt="apollo-layout" />
        </div>
      </div>
    </body>

  );
}



export default Error500Screen;
