import React from 'react';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  TypeInfoContainer,
  TypeRightView,
  TypeTitle,
  TypeDescription,
  TypeSection,
  TypeItemsContent,
  TypeItemsImageContent,
  TypeItemsInfoTitle,
  TypeButtonContainer,
} from './styled';



export interface IProps {
  id?: string;

  data: Array<any>;
  title: string;
  description?: string;

  buttonText?: string;
  buttonPress?: any;
}



const ContentBusinessType: React.FC<IProps> = (props) => {
  return (

    <TypeInfoContainer
      id={props.id}>

      <TypeRightView>

        <TypeTitle>
          {props.title.toUpperCase()}
        </TypeTitle>


        <TypeDescription>
          {props.description}
        </TypeDescription>

      </TypeRightView>



      <TypeSection>
        {props.data?.map((itemGrid: any) => (

          <TypeItemsContent>

            <TypeItemsImageContent>
              {itemGrid.icon}
            </TypeItemsImageContent>

            <TypeItemsInfoTitle>
              {itemGrid.title}
            </TypeItemsInfoTitle>

          </TypeItemsContent>

        ))}
      </TypeSection>



      {props.buttonText && (
        <TypeButtonContainer>
          <ButtonAccess
            text={props.buttonText}
            onPress={props.buttonPress}
          />
        </TypeButtonContainer>
      )}

    </TypeInfoContainer>

  );
};



export default ContentBusinessType;
