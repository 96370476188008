export const APP_ROUTES = {
  LandingNoitadaScreen:             '/',
  OrganizerAboutLandingScreen:      '/organizer',
  OrganizerDetailsLandingScreen:    '/organizer-about',
  ProLandingScreen:                 '/pro',
  BlogScreen:                       '/blog',
  AboutScreen:                      '/about',
  ABetterNightScreen:               '/a-better-night',
  CountriesScreen:                  '/countries',

  CoinsScreen:                      '/coins',

  DevelopersScreen:                 '/developers',

  HelpCenterScreen:                 '/help',
  HelpPartnerScreen:                '/help/user',
  HelpUserScreen:                   '/help/partner',
  ContactScreen:                    '/contact',

  SocialLinksScreen:                '/linkbio',

  PressScreen:                      '/press',

  TermsPoliciesScreen:              '/terms-policy',

  TermsNoitadaLicenseScreen:        '/terms-policy/noitada-license',
  TermsNoitadaPrivacityScreen:      '/terms-policy/noitada-privacity',
  TermsNoitadaUseScreen:            '/terms-policy/noitada-use',
  TermsNoitadaCookiesScreen:        '/terms-policy/noitada-cookies',

  TermsOrganizerLicenseScreen:      '/terms-policy/organizer-license',
  TermsOrganizerPrivacityScreen:    '/terms-policy/organizer-privacity',
  TermsOrganizerUseScreen:          '/terms-policy/organizer-use',
  TermsOrganizerCookiesScreen:      '/terms-policy/organizer-cookies',
  TermsOrganizerDataScreen:         '/terms-policy/organizer-data',

  TermsOthersJobScreen:             '/terms-policy/other-jobs',

  JobsScreen:                       '/jobs',

  GetAppsLandingScreen:             '/app',

  FestivalHomeScreen:               '/festival',
  FestivalLineUpScreen:             '/festival/line-up',

  Error403Screen:                   '/403',
  Error404Screen:                   '/404',
  Error500Screen:                   '/500',
};



const NameRoutes = {
  ...APP_ROUTES,
};



export default NameRoutes;
