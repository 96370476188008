import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

import {
  PaddingContainer,
} from '../../styles/styled.layout';



export const HelpContentSegments = styled(PaddingContainer)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 44px;
  background-color: ${Colors.helpBackground};
`;



export const HelpFormContactView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  flex-wrap: wrap;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
  }
`;


export const HelpEmailItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 50%;
  height: 210px;
`;

export const HelpEmailIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
`;

export const HelpEmailTitle = styled.h3`
  color: ${Colors.black};
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const HelpEmailDescription = styled.p`
  color: ${Colors.black};
  font-size: 16px;
  margin-bottom: 8px;
`;

export const HelpEmailDescButton = styled.a`
  color: ${Colors.primary};
  font-weight: bold;
`;

export const HelpValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 7px;
`;

export const HelpEmailValue = styled.a`
  display: flex;
  padding-right: 7px;
  padding-bottom: 5px;
  color: ${Colors.primary};
  font-size: 18px;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 0px 100%, 100% 100%;
  background-size: 0px 8%, 100% 8%;
  transition: all 0.2s ease-in-out 0s;
  background-image: linear-gradient(120deg, ${Colors.primary} 0%, ${Colors.primary} 100%), linear-gradient(120deg, transparent, transparent);
  
  :focus,
  :hover {
    background-size: 100% 8%, 100% 8%;
  }
`;



export const HelpFormContactRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const HelpFormCreateTitle = styled.h3`
  width: 60%;
  color: ${Colors.black};
  font-size: 20px;
`;

export const HelpFormCreateButton = styled.div`
  display: flex;
  flex-direction: column;
`;
