import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
  SubTitleContents,
} from '../../../styles/styled.layout';



export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.black};
`;

export const StepsTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const StepsTitle = styled(TitleContents)`
`;

export const StepsSubtitle = styled(SubTitleContents)`
  margin-top: 15px;
  color: ${Colors.muted};
`;
