import React from 'react';

import {
  CtaContainer,
  HeaderContent,
  TermsBlock,
  TermsTitle,
  TermsText,
} from './styled';



export interface IProps {
  title: string;
  backgroundImage?: string;
  description?: string;
}



const HelpHeader: React.FC<IProps> = (props) => {
  return (



    <CtaContainer
      style={{
        backgroundImage: 'url(' + props.backgroundImage + ')',
      }}>

      <HeaderContent>

        <TermsBlock>
          <TermsTitle>
            {props.title}
          </TermsTitle>


          {props.description && (
            <TermsText>
              {props.description}
            </TermsText>
          )}

        </TermsBlock>

      </HeaderContent>

    </CtaContainer>

  );
};



export default HelpHeader;
