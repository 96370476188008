import _mouseScrollDown from '../assets/animations/mouse-scroll-down.gif';



export const LANDINGS = {
  mouseScrollDown:                  _mouseScrollDown,
};



const Animations = {
  ...LANDINGS,
};


  
export default Animations;
