import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import ButtonWhatsApp from '../../../components/Buttons/ButtonWhatsApp';

import ContentAbout from '../../../components/Containers/ContentAbout';
import DownloadApp from '../../../components/Containers/DownloadApp';
import LandingHeader from '../../../components/Containers/LandingHeader';
import ContentBenefits from '../../../components/Contents/ContentBenefits';
import ContentInfoImages from '../../../components/Contents/ContentInfoImages';
import FooterApp from '../../../components/Footers/FooterApp';
import NavbarLanding from '../../../components/Navbar/NavbarLanding';
import TreeScreens from '../../../components/TreeScreens';

import {
  openSite,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
  Images,
  Keys,
  Videos,
} from '../../../shared/constants';

import Vars from '../../../shared/environments/variables';

import {
  ImageBenefitsIcon,
} from './styled';



const LandingNoitadaScreen: React.FC = () => {
  const history = useHistory();


  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_initial',
    },
    {
      navTitle: 'Download',
      navHref: '#download',
    },
    {
      navTitle: 'A Noitada',
      navHref: '#noitada_app',
    },
    {
      navTitle: 'Vantagens',
      navHref: '#benefits',
    },
    {
      navTitle: 'Noitada para Parceiros',
      navHref: '#noitada_organizer',
    },
    {
      navTitle: 'Sobre nós',
      navHref: '#about_us',
    },
  ];


  const itemsFeats = [
    {
      icon: (
        <ImageBenefitsIcon
          src={Images.tutorial1}
        />
      ),
      title: 'Diversão',
      text: 'A maneira mais fácil de ir a festas, baladas, pubs ou à bares favoritos com seus amigos!',
    },
    {
      icon: (
        <ImageBenefitsIcon
          src={Images.tutorial2}
        />
      ),
      title: 'Praticidade',
      text: 'A melhor ferramenta para encontrar onde será sua próxima Noitada!',
    },
    {
      icon: (
        <ImageBenefitsIcon
          src={Images.tutorial3}
        />
      ),
      title: 'Preços',
      text: 'Descontos e promoções em toda sua noitada!',
    },
    {
      icon: (
        <ImageBenefitsIcon
          src={Images.tutorial4}
        />
      ),
      title: 'Vai Viajar?',
      text: 'Viaje e leve-nos com você. Nós vamos ser grandes companheiros!',
    },
  ];


  function openNoitada() {
    openSite(Vars().appNoitadaSite);
  }


  function openAccoutOrganizerRegister() {
    openSite(Vars().contaOrganizerRegister);
  }


  function openAccoutOrganizerAbout() {
    history.push(NameRoutes.OrganizerAboutLandingScreen);
  }



  return (

    <NavbarLanding
      type={'SCROLL'}
      navItems={navItemsPage}>


      <ButtonWhatsApp />



      <LandingHeader
        id={'page_initial'}
        logo={Images.logoNoitadaIconWhite}
        title={'Noitada'.toUpperCase()}
        subtitle={'Para curtir a noite!'}
        video={Videos.landingNoitadaAppVideo}
        buttonText={'Acessar agora'}
        buttonPress={() => {
          openNoitada();
        }}
      />



      <DownloadApp
        showOtherApps
        id={'download'}
        title={'Navegador ou aplicativo? Você escolhe!'}
        description={'Leve o aplicativo da Noitada onde quer que você vá, a um clique!'}
        linkGooglePlay={Keys.noitadaMobileAppAndroidLink}
        linkAppStore={Keys.noitadaMobileAppIosLink}
      />



      <ContentInfoImages
        id={'noitada_app'}
        image={Images.noitadaHome}
        title={'A Noitada'}
        description={`
          A Noitada é uma plataforma que traz bares, baladas, festas, carnavais, festivas, e muito mais.
          Todas as informações estão em um único lugar para você conferir a programação completa de um jeito fácil e prático.
          Tudo a apenas um clique de distância.
        `}
      />



      <ContentAbout
        title={'Seu guia está aqui!'}
        subtitle={'As informações da noite, só para você. Basta se cadastrar e começar a descobrir novos lugares, festas e festivais, perto ou longe!'}
        backgroundImage={Images.backgroundIndex2}
        buttonOneText={'Acessar agora'}
        buttonOnePress={() => {
          openNoitada();
        }}
      />



      <ContentBenefits
        id={'benefits'}
        data={itemsFeats}
        image={Images.noitadaPcMobile}
        title={'Algumas das vantagens'}
        description={'Trazemos soluções que te ajudam a ter uma ótima Noitada com:'}
      />



      <ContentAbout
        title={'Otimize sua noitada!'}
        subtitle={'Explore uma infinidade de locais com facilidade, descubra promoções especiais, agilize sua noitada com diversas ferramentas, e foque no que é mais importante... APROVEITAR.'}
        backgroundImage={Images.backgroundIndex3}
        buttonOneText={'Acessar agora'}
        buttonOnePress={() => {
          openNoitada();
        }}
      />



      <ContentInfoImages
        id={'noitada_organizer'}
        backgroundColor={Colors.white}
        titleColor={Colors.black}
        textColor={Colors.grayDark}
        image={Images.organizerHome}
        title={'Noitada Organizer para parceiros'}
        description={'Gerencie seu negócio com nossa plataforma que te ajuda sem complicações. Veja todas as nossas ferramentas, e se não encontrar o que procura entre em contato conosco, estamos sempre aqui!'}
        buttonOneText={'Criar perfil'}
        buttonOnePress={() => {
          openAccoutOrganizerRegister();
        }}
        buttonTwoText={'Conhecer mais'}
        buttonTwoPress={() => {
          openAccoutOrganizerAbout();
        }}
      />



      <TreeScreens id={'about_us'} />
      <FooterApp />

    </NavbarLanding>

  );
};



export default LandingNoitadaScreen;
