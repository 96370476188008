import React from 'react';

import ButtonAccess from '../../../../components/Buttons/ButtonChoose';
import ButtonWhatsApp from '../../../../components/Buttons/ButtonWhatsApp';
import ContainerContact from '../../../../components/Containers/ContainerContact';

import LandingHeader from '../../../../components/Containers/LandingHeader';
import ContentAllTeam from '../../../../components/Contents/ContentAllTeam';
import ContentHowWorks from '../../../../components/Contents/ContentHowWorks';
import ContentInfoImages from '../../../../components/Contents/ContentInfoImages';
import ContentPRO from '../../../../components/Contents/ContentPRO';
import StepsCarousel from '../../../../components/Contents/StepsCarousel';
import FooterApp from '../../../../components/Footers/FooterApp';
import NavbarLanding from '../../../../components/Navbar/NavbarLanding';
import TreeScreens from '../../../../components/TreeScreens';

import {
  openSite,
} from '../../../../config/linking.config';

import {
  Colors,
  Images,
  Videos,
} from '../../../../shared/constants';

import Vars from '../../../../shared/environments/variables';

import {
  LandingAboutContent,
  LandingOrganizerViews,
  LandingOrganizerLogoView,
  LandingOrganizerImage,
  LandingOrganizerText,
  LandingOrganizerTitle,
  LandingOrganizerDescription,
  LandingOrganizerButton,
} from './styled';



const OrganizerDetailsLandingScreen: React.FC = () => {
  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_initial',
    },
    /*
    {
      navTitle: 'Sua Noitada',
      navHref: '#steps',
    },
    */
    {
      navTitle: 'Como funciona',
      navHref: '#how-works',
    },
    {
      navTitle: 'PRO',
      navHref: '#pro',
    },
    {
      navTitle: 'Contato',
      navHref: '#contact',
    },
    {
      navTitle: 'Sobre nós',
      navHref: '#about_us',
    },
  ];



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <ButtonWhatsApp />



      <LandingHeader
        id={'page_initial'}
        video={Videos.landingOrganizerInfoVideo}>
        <LandingAboutContent>

          <LandingOrganizerViews>

            <LandingOrganizerLogoView>
              <LandingOrganizerImage
                src={Images.logoOrganizerAppIcon}
              />

              <LandingOrganizerText>
                Organizer
              </LandingOrganizerText>
            </LandingOrganizerLogoView>


            <LandingOrganizerTitle>
              Crie, divulgue e venda sem nenhuma complicação!
            </LandingOrganizerTitle>


            <LandingOrganizerDescription>
              Ferramentas para te auxiliar nas vendas e te ajuda a organizar, acompanhar seu evento em tempo real.
            </LandingOrganizerDescription>


            <LandingOrganizerButton>
              <ButtonAccess
                text={'Criar conta grátis'}
                onPress={() => {
                  openSite(Vars().organizerNoitadaSite);
                }}
              />
            </LandingOrganizerButton>

          </LandingOrganizerViews>

        </LandingAboutContent>
      </LandingHeader>



      {/*
      <StepsCarousel
        id={'steps'}
        title={'Sua Noitada'}
      />
      */}



      <ContentAllTeam
        title={('Ponta a Ponta').toUpperCase()}
      />



      <ContentHowWorks
        id={'how-works'}
      />



      {/*
      <ContentInfoImages
        backgroundColor={[
          Colors.linearTopApp,
          Colors.linearBottomApp,
        ]}
        image={Images.moneySymbols}
        title={'Várias moedas'}
        description={'Venda em qualquer moeda para qualquer pessoa ao redor do mundo visitando seu perfil e receba em sua moeda corrente.'}
      />
      */}



      <ContentPRO
        id={'pro'}
      />



      <ContainerContact
        id={'contact'}
      />



      <TreeScreens id={'about_us'} />
      <FooterApp />

    </NavbarLanding>

  );
};



export default OrganizerDetailsLandingScreen;
