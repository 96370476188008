import React from 'react';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  ContentInfoContainer,
  InfoContent,
  InfoPartnerContent,
  InfoButtonContent,
  InfoImageView,
  LandingJobsContainer,
  InfoTitlesView,
  InfoTitle,
  InfoDescription,
} from './styled';



export interface IProps {
  id?: string;

  image?: any;
  title?: string;
  description?: string;

  backgroundColor?: string | Array<string>;
  titleColor?: string;
  textColor?: string;

  buttonOneText?: string;
  buttonOnePress?: any;
  buttonTwoText?: string;
  buttonTwoPress?: any;

  right?: boolean;
  left?: boolean;
}



const ContentInfoImages: React.FC<IProps> = (props: IProps) => {
  function renderButton(buttonFunctionText: string, buttonFunctionPress: any) {
    return buttonFunctionText && (
      <ButtonAccess
        color={props.titleColor}
        text={buttonFunctionText}
        onPress={buttonFunctionPress}
      />
    );
  }



  return (

    <ContentInfoContainer
      id={props.id}
      backgroundColor={props.backgroundColor}>

      <InfoContent>

        <InfoImageView>
          <LandingJobsContainer
            src={props.image}
          />
        </InfoImageView>



        <InfoPartnerContent>

          <InfoTitlesView>

            <InfoTitle
              color={props.titleColor}>
              {props.title ? props.title.toUpperCase() : '-'}
            </InfoTitle>


            <InfoDescription
              color={props.textColor}>
              {props.description}
            </InfoDescription>

          </InfoTitlesView>



          {(props.buttonOneText || props.buttonTwoText) && (
            <InfoButtonContent>
              {renderButton(props.buttonOneText || '-', props.buttonOnePress)}
              {renderButton(props.buttonTwoText || '-', props.buttonTwoPress)}
            </InfoButtonContent>
          )}

        </InfoPartnerContent>

      </InfoContent>

    </ContentInfoContainer>

  );
};



ContentInfoImages.defaultProps = {
  left: true,
  right: false,
};



export default ContentInfoImages;
