import {
  Images,
} from '../constants';



export interface IProCompanyLanding {
  image: string;
  name: string;
  idShort: string;
  country: string;
}



export const placesProArray: Array<IProCompanyLanding> = [
  {
    image: Images.TEYWZ,
    name: 'Cantinho Da Jô',
    idShort: 'TEYWZ',
    country: 'BRA',
  },
  {
    image: Images.PTCMI,
    name: 'Restaurante Porto Caymmi',
    idShort: 'PTCMI',
    country: 'BRA',
  },
];
