import styled,
{
  css,
} from 'styled-components';

import {
  GetPlatform,
} from '../../../../config/plataform.config';

import {
  Colors,
} from '../../../../shared/constants';



export const CardItemHover = css`
  :hover {
    border-color: ${Colors.accent};
    box-shadow: 0 0 5px ${Colors.buttonOk},
    0 0 5px ${Colors.buttonOk},
    0 0 20px ${Colors.buttonOk},
    0 0 20px ${Colors.buttonOk};
  }
`;

export const AboutPresskitCard = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 260px;
  background-color: ${Colors.cardBackground};
  box-shadow: rgba(25, 31, 40, 0.15) 0px 1px 2px 0px;
  border-color: ${Colors.primary};
  border-style: solid;
  border-width: 0.5px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease 0s;

  ${GetPlatform() === 'WEB' && CardItemHover}
`;

export const AboutPresskitCardImage = styled.img`
  background-color: ${Colors.black};
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

export const AboutPresskititems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding-top: 13px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 17px;
`;

export const AboutPresskitTitle = styled.h2`
  margin-bottom: 7px;
  color: ${Colors.white};
  font-size: 18px;
  text-align: center;
`;

export const AboutPresskitText = styled.h2`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 200;
  text-align: center;
`;


export const ButtonPressKitHover = css`
  ${AboutPresskitCard}:hover & {
    color: ${Colors.white};
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }
`;

export const AboutPresskitDownload = styled.p`
  width: 80%;
  margin-top: 13px;
  padding: 10px;
  color: ${Colors.buttonOk};
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-color: ${Colors.buttonOk};
  border-style: solid;
  border-width: 1px;
  border-radius: 50px;
  transition: all 250ms ease 0s;

  ${GetPlatform() === 'WEB' && ButtonPressKitHover}
`;
