import React from 'react';

import {
  AboutPresskitCard,
  AboutPresskitCardImage,
  AboutPresskititems,
  AboutPresskitTitle,
  AboutPresskitText,
  AboutPresskitDownload,
} from './styled';



export interface IProps {
  title: string;
  image?: string;
  description?: string;

  onPress?: any;
}



const CardItems: React.FC<IProps> = (props) => {
  return (

    <AboutPresskitCard
      onClick={props.onPress}>

      <AboutPresskitCardImage
        alt={props.title}
        src={props.image}
      />



      <AboutPresskititems>

        <AboutPresskitTitle>
          {props.title}
        </AboutPresskitTitle>


        {props.description && (
          <AboutPresskitText>
            {props.description}
          </AboutPresskitText>
        )}


        {props.onPress && (
          <AboutPresskitDownload>
            {'Baixar arquivos'.toUpperCase()}
          </AboutPresskitDownload>
        )}

      </AboutPresskititems>

    </AboutPresskitCard>

  );
};



export default CardItems;
