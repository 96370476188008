import React from 'react';

import {
  openSite,
} from '../../../config/linking.config';

import Vars from '../../../shared/environments/variables';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  BenefitsRowContainer,
  BenefitsContent,
  BenefitsInfoInnerContent,
  BenefitsInfoTitle,
  BenefitsInfoSubTitle,
  BenefitsItemsContent,
  BenefitsItemsImageContent,
  BenefitsItemsInfoContent,
  BenefitsItemsInfoText,
  BenefitsItemsInfoTitle,
  BenefitsSection,
  ImageLeftView,
  ImageScreenApp,
  BenefitsButtonContent,
} from './styled';



export interface IProps {
  id?: string;

  data?: Array<any>;
  image?: any;
  title: string;
  description?: string;

  items?: any;
}



const ContentBenefits: React.FC<IProps> = (props: IProps) => {
  function renderButton(buttonFunctionText: string, buttonFunctionPress: any) {
    return buttonFunctionText && (
      <ButtonAccess
        text={buttonFunctionText}
        onPress={buttonFunctionPress}
      />
    );
  }


  function openNoitada() {
    openSite(Vars().appNoitadaSite);
  }


  function openAccoutOrganizerRegister() {
    openSite(Vars().contaOrganizerRegister);
  }



  return (

    <BenefitsRowContainer
      id={props.id}>

      <ImageLeftView>
        <ImageScreenApp
          alt={''}
          src={props.image}
        />
      </ImageLeftView>



      <BenefitsContent>

        <BenefitsInfoInnerContent>
          <BenefitsInfoTitle>
            {props.title?.toUpperCase()}
          </BenefitsInfoTitle>

          <BenefitsInfoSubTitle>
            {props.description}
          </BenefitsInfoSubTitle>
        </BenefitsInfoInnerContent>



        <BenefitsSection>

          {props.data?.map((itemGrid: any) => (
            <BenefitsItemsContent>

              <BenefitsItemsImageContent>
                {itemGrid.icon}
              </BenefitsItemsImageContent>


              <BenefitsItemsInfoContent>
                <BenefitsItemsInfoTitle>
                  {itemGrid.title}
                </BenefitsItemsInfoTitle>


                <BenefitsItemsInfoText>
                  {itemGrid.text}
                </BenefitsItemsInfoText>
              </BenefitsItemsInfoContent>

            </BenefitsItemsContent>
          ))}

        </BenefitsSection>



        <BenefitsButtonContent>

          {renderButton(
            'Acessar agora',
            () => {
              openNoitada();
            },
          )}


          {renderButton(
            'Criar conta grátis',
            () => {
              openAccoutOrganizerRegister();
            },
          )}

        </BenefitsButtonContent>

      </BenefitsContent>

    </BenefitsRowContainer>

  );
};



export default ContentBenefits;
