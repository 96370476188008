import React,
{
  useState,
} from 'react';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  EProTypes,
} from '../../../../shared/enums';

import {
  OffersContainer,
  OffersImageBackground,
  ProOffersOverlay,
  OffersContent,
  ProPlansScrollStyled,
  ProPlansWrapperView,
  ProPlansSectionScrollText,
  OffersTableContent,
  OffersTable,
  OffersTitlesTr,
  OffersSingleTh,
  OffersSingleTitle,
  OffersSingleSubTitle,
  OffersSingleSectionTitle,
  OffersTypeTitle,
  OffersItemViewTitle,
  OffersItemText,
  OffersItemViewText,
} from './styled';



const SectionProOffers: React.FC = () => {
  const [colHoverNthChild, setcolHoverNthChild] = useState<number | undefined>(undefined);



  return (

    <OffersContainer>

      <OffersImageBackground
        alt={''}
        src={Images.backgroundPROOffers}
      />

      <ProOffersOverlay
        angle={180}
        locations={[
          0.5,
          0.9,
        ]}
        colors={[
          Colors.alertOverlay,
          Colors.black,
        ]}
      />



      <OffersContent>

        <ProPlansSectionScrollText>
          Arraste para o lado para ver todas as opções nos planos da Noitada.
        </ProPlansSectionScrollText>



        <ProPlansScrollStyled>
          <ProPlansWrapperView>

            <OffersTableContent>
              <OffersTable colHoverNthChild={colHoverNthChild}>

                <OffersTitlesTr>

                  <OffersSingleSectionTitle>
                    O que o PRO oferece:
                  </OffersSingleSectionTitle>


                  <OffersTypeTitle>
                    <OffersItemViewTitle id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Sem PRO
                    </OffersItemViewTitle>
                  </OffersTypeTitle>


                  <OffersTypeTitle>
                    <OffersItemViewTitle id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Light
                    </OffersItemViewTitle>
                  </OffersTypeTitle>


                  <OffersTypeTitle>
                    <OffersItemViewTitle id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Medium
                    </OffersItemViewTitle>
                  </OffersTypeTitle>


                  <OffersTypeTitle>
                    <OffersItemViewTitle id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Super
                    </OffersItemViewTitle>
                  </OffersTypeTitle>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Suporte ao parceiro
                    </OffersSingleTitle>
                  </OffersSingleTh>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Sem suporte
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Seg-sex, das 9h às 18h
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Seg-sex, das 9h às 18h
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Todos os dias, 24/7
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Vendas
                    </OffersSingleTitle>

                    <OffersSingleSubTitle>
                      Pagamentos no local e online
                    </OffersSingleSubTitle>
                  </OffersSingleTh>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Não
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Prazo para receber suas vendas
                    </OffersSingleTitle>
                  </OffersSingleTh>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Sem vendas
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Fechamento mensal
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Até 25 dias após
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Até 20 dias
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Parcelamento
                    </OffersSingleTitle>
                  </OffersSingleTh>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Sem vendas
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Apenas 1x
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Até 2x
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Até 3x
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Treinamento
                    </OffersSingleTitle>
                  </OffersSingleTh>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Não
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Criação de reservas
                    </OffersSingleTitle>
                  </OffersSingleTh>

                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Não
                    </OffersItemViewText>
                  </OffersItemText>

                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Abertura de comanda
                    </OffersSingleTitle>
                  </OffersSingleTh>

                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Não
                    </OffersItemViewText>
                  </OffersItemText>

                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>



                <OffersTitlesTr>

                  <OffersSingleTh>
                    <OffersSingleTitle>
                      Adicionar itens do cardapio em comandas
                    </OffersSingleTitle>
                  </OffersSingleTh>

                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.NO_PRO} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(2)}>
                      Não
                    </OffersItemViewText>
                  </OffersItemText>

                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.LIGHT} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(3)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.MEDIUM} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(4)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>


                  <OffersItemText>
                    <OffersItemViewText id={EProTypes.SUPER} onMouseOut={() => setcolHoverNthChild(undefined)} onMouseOver={() => setcolHoverNthChild(5)}>
                      Sim
                    </OffersItemViewText>
                  </OffersItemText>

                </OffersTitlesTr>

              </OffersTable>
            </OffersTableContent>

          </ProPlansWrapperView>
        </ProPlansScrollStyled>

      </OffersContent>

    </OffersContainer>

  );
};



export default SectionProOffers;
