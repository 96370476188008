import React from 'react';

import NameRoutes from '../../../navigation/names';

import {
  FooterRightsContainer,
  RightsTextP,
  ListInline,
  ListFooterText,
  ListButton,
} from './styled';



const FooterApp: React.FC = () => {
  return (

    <FooterRightsContainer>

      <RightsTextP>
        &copy; {new Date().getFullYear()} Noitada. Todos os direitos reservados.
      </RightsTextP>



      <ListInline>

        <ListButton
          to={NameRoutes.HelpCenterScreen}>
          <ListFooterText>
            Ajuda
          </ListFooterText>
        </ListButton>


        <ListButton
          to={NameRoutes.BlogScreen}>
          <ListFooterText>
            Blog
          </ListFooterText>
        </ListButton>


        <ListButton
          to={NameRoutes.TermsPoliciesScreen}>
          <ListFooterText>
            Termos e Políticas
          </ListFooterText>
        </ListButton>

      </ListInline>
    </FooterRightsContainer>

  );
};



export default FooterApp;
