import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
} from '../../../styles/styled.layout';



export const ContactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.appBackground};
`;



export const ContactSectionTitle = styled(TitleContents)`
  text-align: center;
`;

export const ContactSectionSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
