
import {
  Images,
} from '../constants';



export const locationCountryArray: Array<any> = [
  {
    code: 'BRA',
    label: 'BRAZIL',
    flagImage: Images.countryBrazilFlag,
    moneyFull: 'BRL (R$)',
    moneySymbol: 'R$',
  },
  {
    code: 'POR',
    label: 'PORTUGAL',
    flagImage: Images.countryPortugalFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'USA',
    label: 'UNITED_STATES',
    flagImage: Images.countryUnitedStatesFlag,
    moneyFull: 'USA ($)',
    moneySymbol: '$',
  },
  {
    code: 'ESP',
    label: 'SPAIN',
    flagImage: Images.countrySpainFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'ITA',
    label: 'ITALY',
    flagImage: Images.countryItalyFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'FRA',
    label: 'FANCE',
    flagImage: Images.countryFranceFlag,
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
];
