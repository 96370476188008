import React from 'react';

import {
  Animations,
} from '../../../shared/constants';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  LandingHeaderStyle,
  LandingHeaderContainerStyle,
  HeaderContent,
  LandingAppLogo,
  TextsContent,
  LandingHeaderTitle,
  SubtitleLandingHeader,
  LandingButtonView,
  HeaderChildrenContent,
  LandingMouseDownView,
  LandingMouseDownImage,
  LandingVideoOverlay,
  LandingVideoBackground,
} from './styled';



export interface IProps {
  id: string;

  logo?: string;
  video?: any;
  title?: string;
  subtitle?: string;

  buttonText?: string;
  buttonPress?: any;

  children?: any;
}



const LandingHeader: React.FC<IProps> = (props: any) => {
  return (

    <LandingHeaderStyle
      id={props.id}>

      <LandingHeaderContainerStyle>

        {!props.children && (
          <HeaderContent>

            {props.logo && (
              <LandingAppLogo
                alt={'Logo'}
                src={props.logo}
              />
            )}



            <TextsContent>

              {props.title && (
                <LandingHeaderTitle>
                  {props.title}
                </LandingHeaderTitle>
              )}


              {props.subtitle && (
                <SubtitleLandingHeader>
                  {props.subtitle}
                </SubtitleLandingHeader>
              )}

            </TextsContent>



            {props.buttonText && (
              <LandingButtonView>

                <ButtonAccess
                  text={props.buttonText}
                  onPress={props.buttonPress}
                />

              </LandingButtonView>
            )}

          </HeaderContent>
        )}



        {props.children && (
          <HeaderChildrenContent>
            {props.children}
          </HeaderChildrenContent>
        )}



        <LandingMouseDownView>
          <LandingMouseDownImage
            src={Animations.mouseScrollDown}
          />
        </LandingMouseDownView>

      </LandingHeaderContainerStyle>



      <LandingVideoOverlay />



      <LandingVideoBackground
        loop
        autoPlay
        muted
        plays-inline>

        <source src={props.video} type={'video/mp4'} />
        <source src={props.video} type={'video/ogg'} />
        Your browser does not support the video tag.

      </LandingVideoBackground>

    </LandingHeaderStyle>

  );
};



export default LandingHeader;
