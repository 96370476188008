/* eslint-disable react/no-unescaped-entities */
import React from 'react';



const TermsOrganizerDataScreen: React.FC = () => {
  return (

    <body style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      color: 'black',
    }}>

      <h1>
        NOITADA ORGANIZER
      </h1>

      <h2> TERMOS DE DADOS</h2>


    </body>


  );
};



export default TermsOrganizerDataScreen;
