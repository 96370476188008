import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const LandingAboutContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;



export const AboutHeaderContainer = styled.div`
  width: 50%;
`;

export const AboutHeaderTitle = styled.h2`
  margin-top: 8px;
  color: ${Colors.white};
  font-size: 60px;
  font-weight: 500;
`;

export const AboutHeaderText = styled.p`
  margin-top: 24px;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 400;
`;

