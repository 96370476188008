export function GetPlatform(): 'ANDROID' | 'IOS' | 'WEB' | null {
  const isMobileAndroid = /Android/i.test(navigator.userAgent);
  const isMobileIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  if (isMobileAndroid) {
    return 'ANDROID';
  }

  if (isMobileIos) {
    return 'IOS';
  }

  if (!isMobileAndroid && !isMobileIos) {
    return 'WEB';
  }

  return 'WEB';
}
