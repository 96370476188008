import React from 'react';

import Color from 'color';

import {
  IconCalendarStart,
  IconCheck,
  IconCoupon,
  IconCreditCard,
  IconEvents,
  IconOrderMenu,
  IconOrderSheet,
  IconQrCode,
  IconReportsGeneral,
  IconReserves,
  IconShare,
  IconWaiter,
} from '../../../../components/Icons';

import {
  openHelpWhatsApp,
  openSite,
} from '../../../../config/linking.config';

import {
  ReturnProLevelLabel,
} from '../../../../config/user-pro.config';

import {
  Colors,
} from '../../../../shared/constants';

import {
  EProTypes,
} from '../../../../shared/enums';

import Vars from '../../../../shared/environments/variables';

import {
  ProPlansSectionContainer,
  ProPlansSectionViewContent,
  ProPlansSectionTitleView,
  ProPlansSectionScrollText,
  ProPlansHeroTitle,
  ProPlansHeroDescription,
  ProPlansGridContainer,
  ProPlansScrollStyled,
  ProPlansWrapperView,
  ProPlansGridCardItem,
  ProPlanMoreSaleView,
  ProPlanMoreSaleIcon,
  ProPlanMoreSaleText,
  ProPlansGridCardHeader,
  ProPlanTextName,
  ProPlansValuesContainer,
  ProPlanValuesAnualTitleView,
  ProPlanValuesMoneySymbol,
  ProPlanValuesPriceText,
  ProMakePlanOnPressButton,
  ProPlanModulesView,
  ProPlanModulesContent,
  ProPlanModulesTitle,
  ProModuleNameView,
  ProModuleNameIcon,
  ProModuleNameText,
} from './styled';



const SectionPlans: React.FC = () => {
  const iconModuleSize = 20;


  function openAccoutOrganizerRegister() {
    openSite(Vars().contaOrganizerRegister);
  }



  function renderProPlanItem(
    moreSale: boolean,
    type: EProTypes,
    moneySymbol: string,
    valuePrice: number,
  ) {
    const colorMoreSale = Color(Colors.danger).lighten(0.15).toString();


    // Calcula o valor inteiro em reais
    const integerPrice = Math.floor(valuePrice / 100);
    // Calcula os centavos
    const cents = valuePrice % 100;

    // Se não houver valor inteiro, define como 0
    const formattedIntegerPrice = integerPrice === 0 ? '0' : integerPrice.toString();
    // Se não houver centavos, define como 00
    const formattedCents = cents === 0 ? '00' : (cents < 10 ? `0${cents}` : cents.toString());



    return (


      <ProPlansGridCardItem>

        {moreSale && (
          <ProPlanMoreSaleView
            backgroundColor={colorMoreSale}>
            <ProPlanMoreSaleIcon>
              <IconCoupon
                color={Colors.white}
                size={22}
              />
            </ProPlanMoreSaleIcon>

            <ProPlanMoreSaleText>
              Mais vendido
            </ProPlanMoreSaleText>

          </ProPlanMoreSaleView>
        )}



        <ProPlansGridCardHeader>

          <ProPlanTextName>
            Plano {ReturnProLevelLabel(type)}
          </ProPlanTextName>



          <ProPlansValuesContainer>

            <ProPlanValuesAnualTitleView>

              <ProPlanValuesMoneySymbol>
                {moneySymbol || '-'}
              </ProPlanValuesMoneySymbol>

              <ProPlanValuesPriceText
                color={moreSale ? colorMoreSale : Colors.accent}>
                {formattedIntegerPrice}
              </ProPlanValuesPriceText>

              <ProPlanValuesMoneySymbol>
                ,{formattedCents} /mês
              </ProPlanValuesMoneySymbol>

            </ProPlanValuesAnualTitleView>



            <ProMakePlanOnPressButton
              text={type === EProTypes.NO_PRO ? 'Quero me cadastrar' : 'Quero assinar'}
              backgroundColor={moreSale ? colorMoreSale : Colors.primary}
              onPress={() => {
                if (type === EProTypes.NO_PRO) {
                  openAccoutOrganizerRegister();
                  return;
                }

                openHelpWhatsApp(`Olá, gostaria de assinar o plano ${ReturnProLevelLabel(type)} da Noitada. Poderia me ajudar?`);
              }}
            />

          </ProPlansValuesContainer>

        </ProPlansGridCardHeader>



        <ProPlanModulesView>

          <ProPlanModulesContent>

            <ProPlanModulesTitle
              color={moreSale ? colorMoreSale : Colors.accent}>
              O plano inclui:
            </ProPlanModulesTitle>


            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconOrderMenu
                  color={Colors.white}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText>
                Cardápio
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconShare
                  color={Colors.white}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText>
                LinkBio
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconQrCode
                  color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}>
                QR Code
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconOrderSheet
                  color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}>
                Comanda digital
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconWaiter
                  color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}>
                Módulo chamar garçon
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconCalendarStart
                  color={type !== EProTypes.NO_PRO && type !== EProTypes.LIGHT ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO && type !== EProTypes.LIGHT ? Colors.white : Colors.disabled}>
                Calendário para os módulos
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconReserves
                  color={type !== EProTypes.NO_PRO && type !== EProTypes.LIGHT ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO && type !== EProTypes.LIGHT ? Colors.white : Colors.disabled}>
                Reservas
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconEvents
                  color={type !== EProTypes.NO_PRO && type !== EProTypes.LIGHT && type !== EProTypes.MEDIUM ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO && type !== EProTypes.LIGHT && type !== EProTypes.MEDIUM ? Colors.white : Colors.disabled}>
                Eventos
              </ProModuleNameText>
            </ProModuleNameView>

          </ProPlanModulesContent>



          <ProPlanModulesContent>

            <ProPlanModulesTitle
              color={moreSale ? colorMoreSale : Colors.accent}>
              Serviços oferecidos:
            </ProPlanModulesTitle>


            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconCheck
                  color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}>
                Suporte
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconCreditCard
                  color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}>
                Pagamento online
              </ProModuleNameText>
            </ProModuleNameView>



            <ProModuleNameView>
              <ProModuleNameIcon>
                <IconReportsGeneral
                  color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}
                  size={iconModuleSize}
                />
              </ProModuleNameIcon>


              <ProModuleNameText
                color={type !== EProTypes.NO_PRO ? Colors.white : Colors.disabled}>
                Relatórios
              </ProModuleNameText>
            </ProModuleNameView>



          </ProPlanModulesContent>
        </ProPlanModulesView>

      </ProPlansGridCardItem>


    );
  }



  return (

    <ProPlansSectionContainer>

      <ProPlansSectionViewContent>

        <ProPlansSectionTitleView>

          <ProPlansHeroTitle>
            Planos com módulos para otimizar o seu negócio
          </ProPlansHeroTitle>


          <ProPlansHeroDescription>
            Diversas soluções para você aumentar a eficiência de colaboradores, melhorar seus processos, reduzir custos e aumentar suas vendas.
          </ProPlansHeroDescription>

        </ProPlansSectionTitleView>


        <ProPlansSectionScrollText>
          Arraste para o lado para ver todas as opções de planos da Noitada.
        </ProPlansSectionScrollText>

      </ProPlansSectionViewContent>



      <ProPlansGridContainer>
        <ProPlansScrollStyled>

          <ProPlansWrapperView>

            {renderProPlanItem(
              false,
              EProTypes.NO_PRO,
              'R$',
              0,
            )}



            {renderProPlanItem(
              false,
              EProTypes.LIGHT,
              'R$',
              2990,
            )}



            {renderProPlanItem(
              true,
              EProTypes.MEDIUM,
              'R$',
              4490,
            )}



            {renderProPlanItem(
              false,
              EProTypes.SUPER,
              'R$',
              5990,
            )}

          </ProPlansWrapperView>

        </ProPlansScrollStyled>
      </ProPlansGridContainer>

    </ProPlansSectionContainer>

  );
};



export default SectionPlans;
