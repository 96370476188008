import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  WorksContainer,
  WorksTitlesView,
  WorksTitle,
  WorksSubtitle,
  WorksItemsContainer,
  WorksHowImage,
  WorksHowMoneySendView,
  WorksHowMoneyLeft,
  WorksHowMoneyInfoView,
  WorksHowMoneyInfoText,
  WorksHowMoneyInfoTitle,
  WorksHowMoneyRight,
  WorksHowMoneyImage,
  WorkAskContainer,
  WorksAskTitle,
  WorkButtonContainer,
} from './styled';



export interface IProps {
  id?: string;

  buttonText?: string;
  buttonPress?: any;
}



const ContentHowWorks: React.FC<IProps> = (props) => {
  return (

    <WorksContainer
      id={props.id}>

      <WorksTitlesView>
        <WorksTitle>
          {('Saiba como funciona').toUpperCase()}
        </WorksTitle>
      </WorksTitlesView>



      <WorksItemsContainer>

        <WorksHowImage
          src={Images.howWorksStep}
        />


        <WorksHowMoneySendView>

          <WorksHowMoneyLeft>

            <WorksHowMoneyInfoView>
              <WorksSubtitle>
                {'Como funciona os registros'.toUpperCase()}
              </WorksSubtitle>

              <WorksHowMoneyInfoText>
                Parceiros podem se cadastrar na tela de registro <b>aqui</b>, diretamente online.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>


            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoText>
                É obrigatório ter o CNPJ válido e CNAE no ramo de atuação da Noitada (seja alimentício, promoção de eventos ou outros relacionados) para abrir um perfil na Noitada!
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>


            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoText>
                Ainda é preciso uma conta bancária vinculada ao CNPJ (ou ao responsável legal, em caso de MEI), um computador com Windows (a partir do 7) ou um celular e internet para acessar o perfil na Noitada.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>

          </WorksHowMoneyLeft>



          <WorksHowMoneyRight>

            <WorksHowMoneyImage
              src={Images.companyAnalysis}
            />

          </WorksHowMoneyRight>

        </WorksHowMoneySendView>


        <WorksHowMoneySendView>

          <WorksHowMoneyLeft>

            <WorksHowMoneyInfoView>
              <WorksSubtitle>
                {'Como funciona os repasses'.toUpperCase()}
              </WorksSubtitle>

              <WorksHowMoneyInfoText>
                Na hora de comprar, os clientes podem escolher dois tipos de pagamento:
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>


            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoTitle>
                Pagamento pelo restaurante
              </WorksHowMoneyInfoTitle>

              <WorksHowMoneyInfoText>
                Quando o cliente escolhe pagar no local, para o parceiro.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>


            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoTitle>
                Pagamento pela Noitada
              </WorksHowMoneyInfoTitle>

              <WorksHowMoneyInfoText>
                Quando o cliente escolhe pagar em cartão pelos sites, aplicativos ou métodos de pagamento da Noitada.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>

          </WorksHowMoneyLeft>



          <WorksHowMoneyRight>

            <WorksHowMoneyImage
              src={Images.bankTransfer}
            />

          </WorksHowMoneyRight>

        </WorksHowMoneySendView>



        <WorksHowMoneySendView>

          <WorksHowMoneyRight>

            <WorksHowMoneyImage
              src={Images.bankPaymentCalendar}
            />

          </WorksHowMoneyRight>



          <WorksHowMoneyLeft>

            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoText>
                Sempre que o cliente escolher pagar pela Noitada, ficaremos responsáveis por fazer o repasse do valor para sua conta bancária cadastrada.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>


            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoText>
                As vendas são contabilizadas por período e os repasses feitos em até 4 semanas, podendo receber em até 5 dias úteis se optar por antecipar o recebimento. O montante é processado e transferido ao parceiro por uma operadora de pagamentos independente da Noitada.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>


            <WorksHowMoneyInfoView>
              <WorksHowMoneyInfoTitle>
                Receber nota fiscal ao pagar pela Noitada
              </WorksHowMoneyInfoTitle>

              <WorksHowMoneyInfoText>
                Enviamos uma nota fiscal eletrônica (NFe) para o e-mail informado pelo usuário no momento do registro. A nota fiscal eletrônica tem o mesmo valor de uma nota fiscal em papel.
              </WorksHowMoneyInfoText>
            </WorksHowMoneyInfoView>

          </WorksHowMoneyLeft>

        </WorksHowMoneySendView>

      </WorksItemsContainer>



      <WorkAskContainer>

        <WorksAskTitle>
          {'Ainda tem perguntas?'.toUpperCase()}
        </WorksAskTitle>


        <WorkButtonContainer>
          <ButtonAccess
            text={'Mais perguntas'}
            onPress={() => {
              // TODO
            }}
          />
        </WorkButtonContainer>

      </WorkAskContainer>

    </WorksContainer>

  );
};



export default ContentHowWorks;
