const environment = {
  production: true,
  environment: 'PROD',
  title: 'Production Environment Heading',
  apiUrl: 'https://api.noitada.app',
  firebaseWebConfig: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};



export default environment;
