import React from 'react';

import {
  Videos,
} from '../../shared/constants';

import LandingAboutScreen from '../../components/Containers/LandingAbout';
import FooterApp from '../../components/Footers/FooterApp';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import TreeScreens from '../../components/TreeScreens';
import SectionAboutPress from './SectionAboutPress';

import SectionMedias from './SectionMedias';
import SectionPressKit from './SectionPressKit';

// import { Container } from './styles';



const PressScreen: React.FC = () => {
  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_initial',
    },
    {
      navTitle: 'Mais da Noitada',
      navHref: '#more-noitada',
    },
    {
      navTitle: 'Press',
      navHref: '#press',
    },
    {
      navTitle: 'Mídia',
      navHref: '#medias',
    },
    {
      navTitle: 'Sobre nós',
      navHref: '#about_us',
    },
  ];



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <LandingAboutScreen
        id={'page_initial'}
        video={Videos.noitadaAboutVideo}
        title={'Imprensa'}
        description={'Imprensa'}
      />



      <SectionAboutPress
        id={'more-noitada'}
      />



      <SectionPressKit
        id={'press'}
      />



      <SectionMedias
        id={'medias'}
      />



      <TreeScreens id={'about_us'} />
      <FooterApp />

    </NavbarLanding>
  );
};



export default PressScreen;
