import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
  SubTitleContents,
  DescriptionContents,
} from '../../../styles/styled.layout';



export const WorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.appBackground};
  
  @media(max-width: 660px) {
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }
`;

export const WorksTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const WorksTitle = styled(TitleContents)`
  text-align: center;
`;

export const WorksSubtitle = styled(SubTitleContents)`
  color: ${Colors.accent};
  font-weight: bold;
`;



export const WorksItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${Sizes.marginFromTitles};
`;

export const WorksHowImage = styled.img`
  height: 450px;
  object-fit: contain;

  @media (max-width:985px){
    width:100%;
  }
`;



export const WorksHowMoneySendView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  min-height: 300px;
  margin-top: 70px;
  gap: 0 60px;

  @media (max-width: 800px){
    flex-direction: column;
  }
`;


export const WorksHowMoneyLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 300px;
`;

export const WorksHowMoneyInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const WorksHowMoneyInfoText = styled.p`
  margin-top: 10px;
  color: ${Colors.muted};
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
`;

export const WorksHowMoneyInfoTitle = styled(DescriptionContents)`
  color: ${Colors.white};
  font-size: 26px;
  font-weight: bold;
`;


export const WorksHowMoneyRight = styled.div`
  width: 300px;
  height: 300px;

  @media (max-width: 285px){
    width: 270px;
  }
`;

export const WorksHowMoneyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;



export const WorkAskContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;


export const WorksAskTitle = styled(TitleContents)`
  text-align: center;
`;

export const WorkButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Sizes.marginFromTitles};
`;
