import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import { 
  PlatformDevices,
 } from '../../../shared/utils/platforms.utils';

import {
  DescriptionContents,
  PaddingContainer,
  TitleContents,
} from '../../../styles/styled.layout';



export interface IProps {
  color?: string;
  backgroundColor?: string | Array<string>;
}



export const TypeInfoContainer = styled(PaddingContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.appBackground};
`;

export const TypeRightView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const TypeTitle = styled(TitleContents)`
  text-align: center;
`;

export const TypeDescription = styled(DescriptionContents)`
  text-align: center;
`;



export const TypeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 920px;
  margin-top: ${Sizes.marginFromTitles};
  gap: 30px 0;
`;

export const TypeItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  padding-left: 25px;
  padding-right: 25px;

  @media ${PlatformDevices.maxMobileS} {
    width: 100%;
  }

  @media ${PlatformDevices.minMobileS} {
    width: 50%;
  }

  @media ${PlatformDevices.minTablet} {
    width: 25%;
  }

  @media ${PlatformDevices.minLaptopL} {
    width: 230px;
  }
`;

export const TypeItemsImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
`;

export const TypeItemsInfoTitle = styled.h2`
  margin-top: 5px;
  color: ${Colors.accent};
  font-size: 18px;
  font-weight: 100;
  text-align: center;
`;



export const TypeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Sizes.marginFromTitles};
`;
