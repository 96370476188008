import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import ButtonWhatsApp from '../../../../components/Buttons/ButtonWhatsApp';

import ContentAbout from '../../../../components/Containers/ContentAbout';
import DownloadApp from '../../../../components/Containers/DownloadApp';
import LandingHeader from '../../../../components/Containers/LandingHeader';
import ContentBusinessPartners from '../../../../components/Contents/ContentBusinessPartners';
import ContentBusinessTools from '../../../../components/Contents/ContentBusinessTools';
import ContentBusinessType from '../../../../components/Contents/ContentBusinessType';
import ContentPRO from '../../../../components/Contents/ContentPRO';

import FooterApp from '../../../../components/Footers/FooterApp';

import {
  IconTypeBar,
  IconTypeCoffeeShop,
  IconTypeHamburguer,
  IconTypeNightClub,
  IconTypePizzeria,
  IconTypePub,
  IconTypeRestaurant,
  IconTypeSteakhouse,
} from '../../../../components/Icons';

import NavbarLanding from '../../../../components/Navbar/NavbarLanding';
import TreeScreens from '../../../../components/TreeScreens';

import {
  openSite,
} from '../../../../config/linking.config';

import NameRoutes from '../../../../navigation/names';

import {
  Colors,
  Images,
  Keys,
  Videos,
} from '../../../../shared/constants';

import Vars from '../../../../shared/environments/variables';



const OrganizerAboutLandingScreen: React.FC = () => {
  const history = useHistory();

  const sizeIcons = 55;


  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_top',
    },
    // {
    //   navTitle: 'Download',
    //   navHref: '#download',
    // },
    {
      navTitle: 'Negócios',
      navHref: '#bussiness',
    },
    {
      navTitle: 'Vantagens',
      navHref: '#benefits',
    },
    {
      navTitle: 'Parceiros',
      navHref: '#partnes',
    },
    {
      navTitle: 'PRO',
      navHref: '#pro',
    },
    {
      navTitle: 'Sobre nós',
      navHref: '#about_us',
    },
  ];


  const itemsTypePartners = [
    {
      icon: (
        <IconTypeBar
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Bares',
    },
    {
      icon: (
        <IconTypePub
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Pubs',
    },
    {
      icon: (
        <IconTypeNightClub
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Casas Noturnas',
    },
    {
      icon: (
        <IconTypeRestaurant
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Restaurantes',
    },
    {
      icon: (
        <IconTypeCoffeeShop
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Cafeterias',
    },
    {
      icon: (
        <IconTypeHamburguer
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Hamburguerias',
    },
    {
      icon: (
        <IconTypePizzeria
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Pizzarias',
    },
    {
      icon: (
        <IconTypeSteakhouse
          color={Colors.accent}
          size={sizeIcons}
        />
      ),
      title: 'Churrascarias',
    },
  ];


  const itemsComments = [
    {
      logo: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Logo_1x1.jpg',
      name: 'Michelle Damas',
      place: 'DAANCER Club',
      image: 'http://www.idebrasil.com.br/blog/wp-content/uploads/2019/10/vida-de-empreendedor-5-questoes-que-merecem-a-sua-atencao-850x441.jpg',
      comment: 'A melhor parte de trabalharmos com a Noitada foi a praticidade e a comodidade. Conseguimos melhorar nossa eficiência financeira e a experiência dos nossos clientes, sabendo o que ele quer e no momento que ele quer.',
    },
    {
      logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcJvcvlW9cMHT9cfATpipg845gSN7DTyO9xWX4F9wye5nytoIW0EMxLjAvMZxhsmMqQxI&usqp=CAU',
      name: '2',
      place: '2',
      image: '',
      comment: '2',
    },
    {
      logo: 'https://www.zarla.com/images/zarla-brabo-tabaco-1x1-2400x2400-20210623-hfykt9x9qc934874kwhf.png?crop=1:1,smart&width=250&dpr=2',
      name: '3',
      place: '3',
      image: '',
      comment: '3',
    },
    {
      logo: 'https://www.zarla.com/images/zarla-brabo-tabaco-1x1-2400x2400-20210623-p9mmgyhgc6tcb4tf9kwj.png?crop=1:1,smart&width=250&dpr=2',
      name: '4',
      place: '4',
      image: '',
      comment: '4',
    },
    {
      logo: 'https://http2.mlstatic.com/D_NQ_NP_746328-MLB50078987632_052022-O.jpg',
      name: '5',
      place: '5',
      image: '',
      comment: '5',
    },
  ];


  function openAccoutOrganizerRegister() {
    const openWindow = window.open(Vars().contaOrganizerRegister, '_blank');
    if (openWindow) {
      openWindow.focus();
    }
  }



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <ButtonWhatsApp />



      <LandingHeader
        // id={'page_top'}
        id={'page_initial'}
        logo={Images.logoNoitadaIconWhite}
        title={'Organizer'.toUpperCase()}
        subtitle={'Para te ajudar a gerenciar seu Negócio!'}
        video={Videos.landingOrganizerHomeVideo}
        buttonText={'Acessar Agora'}
        buttonPress={() => {
          openSite(Vars().organizerNoitadaSite);
        }}
      />



      {/*
      <DownloadApp
        id={'download'}
        title={'Baixe agora mesmo!'}
        description={'Leve o aplicativo do Organizer onde quer que você vá, a um clique!'}
        linkGooglePlay={Keys.organizerMobileAppAndroidLink}
        linkAppStore={Keys.organizerMobileAppIosLink}
      />
      */}



      <ContentBusinessType
        id={'bussiness'}
        data={itemsTypePartners}
        title={'Prontos para seu negócio'}
        description={'A Noitada foi pensada e desenvolvida para ajudar o seu negócio!'}
        buttonText={'Criar conta grátis'}
        buttonPress={() => {
          openAccoutOrganizerRegister();
        }}
      />



      <ContentAbout
        id={'benefits'}
        title={'Saiba como funciona!'}
        subtitle={'A Noitada é simples, fácil e prática! Diversos módulos e ferramentas que facilitam e otimizam seu negócio, para focar no que realmente importa!'}
        backgroundImage={Images.organizerPdvUse}
        buttonOneText={'Saber Mais'}
        buttonOnePress={() => {
          history.push(NameRoutes.OrganizerDetailsLandingScreen);
        }}
      />



      <ContentBusinessTools />



      <ContentAbout
        id={'partnes'}
        title={'Parceiros!'}
        subtitle={'Veja todas as nossas ferramentas, e se não encontrar o que procura entre em contato conosco, estamos sempre aqui!'}
        backgroundImage={Images.backgroundPartner}
        buttonOneText={'Criar conta grátis'}
        buttonOnePress={() => {
          openAccoutOrganizerRegister();
        }}
      />



      {/*
      <ContentBusinessPartners
        data={itemsComments}
      />
      */}



      <ContentPRO
        id={'pro'}
      />



      <TreeScreens id={'about_us'} />
      <FooterApp />

    </NavbarLanding>

  );
};



export default OrganizerAboutLandingScreen;

