import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const TermsPoliciesTitleView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 200px;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  background-color: ${Colors.shadow};
`;

export const TermsPoliciesTitleInfoView = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

export const TermsPoliciesHeaderTitle = styled.p`
  color: ${Colors.white};
  font-size: 24px;
  font-weight: bold;
`;

export const TermsPoliciesBreadcrumbView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

export const TermsPoliciesBackgroundView = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const TermsPoliciesBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center right;
`;



export const TermsPoliciesDocContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TermsPoliciesDocMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  background-color: ${'#d5daeb'};

  @media ${PlatformDevices.maxTablet} {
    display: none;
  }
`;

export const TermsPoliciesDocItemMenu = styled.a`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;



export const TermsPoliciesDocChildrenView = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  /* max-width: 765px; */
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${Colors.white};

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }
`;



// Intro

export const TermsPoliciesDocIntroView = styled.div`
`;

export const TermsPoliciesDocIntroHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom-color: ${Colors.menuLine};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;


export const TermsPoliciesDocIntroTitle = styled.p`
  margin-bottom: 3px;
  color: ${Colors.black};
  font-size: 31px;
  font-weight: bold;
`;

export const TermsPoliciesDocIntroDate = styled.p`
  color: ${Colors.grayDark};
  font-size: 15px;
`;


export const TermsPoliciesDocIntroInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const TermsPoliciesDocIntroText = styled.p`
  color: ${Colors.black};
  font-size: 16px;
`;



// Sections

export const TermsPoliciesDocSectionView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TermsPoliciesDocSectionTitle = styled.p`
  margin-bottom: 11px;
  color: ${Colors.black};
  font-size: 22px;
  font-weight: bold;
`;


export const TermsPoliciesDocTextView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const TermsPoliciesDocTextDesc = styled.p`
  color: ${Colors.black};
  font-size: 16px;
`;



export const TermsPoliciesDocOptionsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const TermsPoliciesDocOptionsItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TermsPoliciesDocOptionsIcon = styled.p`
  margin-top: 7px;
  margin-right: 7px;
  color: ${Colors.black};
  font-size: 14px;
`;

export const TermsPoliciesDocOptionsText = styled.p`
  margin-top: 7px;
  color: ${Colors.black};
  font-size: 14px;
`;
