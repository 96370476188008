// Landings
import _landingNoitadaAppVideo from '../assets/videos/landing-noitada.mp4';
import _landingOrganizerHomeVideo from '../assets/videos/landing-organizer-home.mp4';
import _landingOrganizerInfoVideo from '../assets/videos/landing-organizer-info.mp4';
import _landingProVideo from '../assets/videos/landing-pro.mp4';
import _noitadaAboutVideo from '../assets/videos/noitada-about.mp4';



export const LANDINGS = {
  landingNoitadaAppVideo:           _landingNoitadaAppVideo,
  landingOrganizerHomeVideo:        _landingOrganizerHomeVideo,
  landingOrganizerInfoVideo:        _landingOrganizerInfoVideo,

  landingProVideo:                  _landingProVideo,

  noitadaAboutVideo:                _noitadaAboutVideo,
};



const Videos = {
  ...LANDINGS,
};


  
export default Videos;
