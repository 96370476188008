import React from 'react';

import {
  StepsContainer,
  StepsTitlesView,
  StepsTitle,
  StepsSubtitle,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionMedias: React.FC<IProps> = (props) => {
  return (

    <StepsContainer
      id={props.id}>

      <StepsTitlesView>
        <StepsTitle>
          {'Mídias'.toUpperCase()}
        </StepsTitle>

        <StepsSubtitle>
          {'props.subtitle'}
        </StepsSubtitle>
      </StepsTitlesView>

    </StepsContainer>

  );
};



export default SectionMedias;
