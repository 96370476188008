import styled from 'styled-components';

import {
  Colors,
} from '../../shared/constants';



export const LinkBioHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.linearTopApp};
`;

// export const LinkBioHeaderBackground = styled.img`
// `;



export const LinkBioInfosContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkBioLogoHeader = styled.img`
  margin-bottom: 5px;
  height: 40px;
  object-fit: contain;
`;

export const LinkBioInfoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
`;

export const LinkBioLogoImage = styled.img`
  width: 96px;
  height: 96px;
  margin: 0 20px;
  background-color: ${Colors.cardBackground};
  border-radius: 58px;
  object-fit: cover;
`;

export const LinkBioAppName = styled.p`
  margin-top: 15px;
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;



export const LinkBioSocialView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const LinkSocialIconButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 9px;
  background-color: ${Colors.white};
  border-radius: 50px;
`;

export const LinkSocialIconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;



export const LinkBioContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
`;



export const LinkBioDescriptionView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${Colors.cardBackground};
  border-radius: 10px;
`;

export const LinkBioDescriptionText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  line-height: 4.8vw;
  text-align: center;
  white-space: pre-line;
`;



export const LinkBioContentButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  background-color: ${Colors.primaryDark};
  border: 1.5px solid ${Colors.black};
  border-radius: 12px;
`;

export const LinkBioContentIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 16px;
`;

export const LinkBioContentText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  word-wrap: break-word;
  text-align: center;
`;


export const LinkBioFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
`;

export const Logocreateprofile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoProfileImage = styled.img`
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  object-fit: contain;
`;

export const OnPrimaryText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;
