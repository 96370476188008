import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-height: ${320 + Sizes.toolbarHeight}px;
  width: 100%;

  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;



export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};

  @media (max-width: 450px){
   padding-left: ${Sizes.mobilePaddingHorizontal};
   padding-right: ${Sizes.mobilePaddingHorizontal};
  }

  @media (max-width: 390px){
    padding-left: 1rem;
    padding-right: 1rem;
   }
`;



export const TermsBlock = styled.div`
  max-width: 530px;
  margin-top: 50px;
  
`;

export const TermsTitle = styled.h1`
  margin-bottom: 24px;
  color: ${Colors.white};
  font-size: 48px;
  line-height: 100%;
  font-weight: 800;
  letter-spacing: 0.6px;
`;

export const TermsText = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: 300;
`;
