import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';

import {
  PaddingContainer,
  SubTitleContents,
  TitleContents,
} from '../../../../styles/styled.layout';



export const UseProContainer = styled(PaddingContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.black};
`;


export const UseProTitle = styled(TitleContents)`
  text-align: center;
`;



export const UseProSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
`;


export const UseProCompanyCard = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 25px;

  @media ${PlatformDevices.maxTablet} {
    min-width: calc(50% - ${Sizes.mobilePaddingVertical});
  }

  @media ${PlatformDevices.minTablet} {
    min-width: calc(25% - ${Sizes.mobilePaddingVertical});
  }
`;

export const UseProImageCompanyView = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 95px;
  height: 95px;
  background-color: ${Colors.cardBackground};
  border-color: ${Colors.primaryDark};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const UseProCompanyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const UseProImageFlagView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-top: 3px;
  padding-left: 7px;
  padding-right: 5px;
  padding-bottom: 2px;
  background-color: ${Colors.primaryDark};
  border-top-left-radius: ${Sizes.cardRadius}px;
`;

export const UseProImageFlagImage = styled.img`
  width: 17px;
  height: 17px;
  object-fit: contain;
`;

export const UseProCompanyTitle = styled.p`
  margin-top: 15px;
  color: ${Colors.white};
  font-size: 18px;
  text-align: center;
`;



export const UseProFooterTitle = styled(SubTitleContents)`
  margin-top: 20px;
  color: ${Colors.accent};

  @media ${PlatformDevices.maxTabletM} {
    margin-bottom: 10px;
  }
`;
