import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
  DescriptionContents,
} from '../../../styles/styled.layout';



export const AboutPresskitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.appBackground};
`;

export const AboutPresskitTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const AboutPresskitTitle = styled(TitleContents)`
`;

export const AboutPresskitSubtitle = styled(DescriptionContents)`
  width: 70%;
  text-align: center;
`;

export const AboutPresskitEmailButton = styled.a`
  margin-top: 1rem;
  cursor: pointer;
`;

export const AboutPresskitEmailText = styled(DescriptionContents)`
  color: ${Colors.accent};
  text-align: center;
`;



export const AboutPresskitSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${Sizes.marginFromTitles};
  max-width: 1170px;
  gap: 0 30px;
`;
