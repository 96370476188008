import React from 'react';

import CardItems from '../../../../components/Cards/Landings/CardItems';

import {
  Images,
} from '../../../../shared/constants';

import {
  ToolsProContainer,
  ToolsProTitlesView,
  ToolsProTitle,
  ToolsProSubtitle,
  ToolsProSection,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionProTools: React.FC<IProps> = (props) => {
  function rendercardPressKit(title: string, image: string, description: string) {
    return (

      <CardItems
        title={title}
        image={image}
        description={description}
      />

    );
  }



  return (

    <ToolsProContainer
      id={props.id}>

      <ToolsProTitlesView>

        <ToolsProTitle>
          {'O PRO da Noitada'.toUpperCase()}
        </ToolsProTitle>


        <ToolsProSubtitle>
          Para pequenos e grandes parceiros, conte com a plataforma mais completa do mercado, não importa o seu tamanho!
        </ToolsProSubtitle>

      </ToolsProTitlesView>



      <ToolsProSection>

        {rendercardPressKit(
          'Diversas ferramentas',
          Images.serviceTickets,
          'LinkBio, cardápio, comandas, reservas, eventos e venda de ingressos são apenas alguns de muitos módulos dentro da Noitada.',
        )}


        {rendercardPressKit(
          'Configurações avançadas',
          Images.serviceAdvancedSettings,
          'Mais autonomia e liberdade para customização da sua Noitada de acordo com suas necessidades.',
        )}


        {rendercardPressKit(
          'Controle de entrada',
          Images.serviceEntrances,
          'Check-in e check-out ao entrar e sair, além do controle de quem está no local presencialmente. E tudo online!',
        )}


        {rendercardPressKit(
          'Suporte e treinamento',
          Images.serviceContacts,
          'Treinamento para sua equipe otimizar suas vendas, atendimento online e suporte.',
        )}

      </ToolsProSection>

    </ToolsProContainer>

  );
};



export default SectionProTools;
