import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  TitleContents,
  DescriptionContents,
} from '../../../styles/styled.layout';



export interface IProps {
  color?: string;
  backgroundColor?: string | Array<string>;
}



export const BackgroundSolid = css`
  background-color: ${(props: IProps) => props.backgroundColor || Colors.appBackground};
`;

export const BackgroundLinearGradient = css`
  background: linear-gradient(
    to right,
    ${(props: IProps) => props.backgroundColor ? props.backgroundColor[0] : Colors.appBackground}, 
    ${(props: IProps) => props.backgroundColor ? props.backgroundColor[1] : Colors.black});
`;



export const ContentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props: IProps) => (!props.backgroundColor || typeof props.backgroundColor === 'string') && BackgroundSolid}
  ${(props: IProps) => typeof props.backgroundColor !== 'string' && (props.backgroundColor && props.backgroundColor?.length > 0) && BackgroundLinearGradient}
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};

  @media ${PlatformDevices.maxTabletM} {
    flex-direction: column-reverse;
    align-items: center;
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }
`;



export const InfoPartnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  margin-left: 30px;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-bottom: ${Sizes.desktopPaddingVertical};

  @media ${PlatformDevices.maxTabletM} {
    width: 100%;
    margin-left: 0;
    padding-top: ${Sizes.mobilePaddingVertical};
    padding-bottom: 10px;
  }
`;

export const InfoButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  @media ${PlatformDevices.minTablet} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 35px;
  }

  @media ${PlatformDevices.minTabletL} {
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
  }
`;



export const InfoImageView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 340px;
  flex: 1;

  @media ${PlatformDevices.maxTabletM} {
    height: auto;
  }
`;

export const LandingJobsContainer = styled.img`
  width: 100%;  
  height: 100%;
  max-height: 340px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

export const InfoTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;


export const InfoTitle = styled(TitleContents)`
  width: 100%;
  color: ${(props: IProps) => props.color || Colors.white};

  @media ${PlatformDevices.maxTabletM} {
    text-align: center;
  }
`;

export const InfoDescription = styled(DescriptionContents)`
  width: 100%;
  color: ${(props: IProps) => props.color || Colors.textDescription};
  text-align: justify;

  @media ${PlatformDevices.maxTabletM} {
    text-align: center;
  }
`;
