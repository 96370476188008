import styled,
{
  css,
} from 'styled-components';

import Color from 'color';

import {
  GetPlatform,
} from '../../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  bold?: boolean;
  disabled?: boolean;
  scale?: any;
  backgroundColor?: string;
  hoverColor?: string;
  color?: string;
}


export const ButtonHover = css`
  :hover {
    transform: translateY(-3px);
    background-color: ${(props: IProps) => props.hoverColor || Colors.buttonOk};
    color: ${(props: IProps) => props.color || Colors.white};
    border-color: ${(props: IProps) => props.hoverColor || Colors.buttonOk};
    box-shadow: 0 0 5px ${(props: IProps) => props.hoverColor || Colors.buttonOk},
    0 0 5px ${(props: IProps) => props.hoverColor || Colors.buttonOk},
    0 0 20px ${(props: IProps) => props.hoverColor || Colors.buttonOk},
    0 0 20px ${(props: IProps) => props.hoverColor || Colors.buttonOk};
  }
`;

export const ButtonActionContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: ${(props: IProps) => props.scale ? (props.scale * 6) + 'px' : '210px'};

  padding-top: ${(props: IProps) => props.scale ? (13 / props.scale * 12) + 'px' : '10px'};
  padding-bottom: ${(props: IProps) => props.scale ? (13 / props.scale * 12) + 'px' : '10px'};
  padding-left: ${(props: IProps) => props.scale ? (45 / props.scale * 10) + 'px' : '45px'};
  padding-right: ${(props: IProps) => props.scale ? (45 / props.scale * 10) + 'px' : '45px'};

  background-color: ${(props: IProps) => props.backgroundColor};
  
  color: ${(props: IProps) => props.color || Colors.white};

  border-width: 1px;
  border-style: solid;
  border-color: ${(props: IProps) => props.color};
  
  border-radius: 50px;
  
  box-shadow: 0 10px 20px -10px ${(props: IProps) => Color(props.backgroundColor).alpha(0.5).toString()};
  transition: all 0.3s ease-out;

  opacity: ${(props: IProps) => !props.disabled ? Sizes.opacityOn : Sizes.opacityOff};

  ${GetPlatform() === 'WEB' && ButtonHover}
`;


export const IconLeftView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  margin-right: 10px;
`;

export const IconRightView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;


export const ButtonTextHover = css`
  ${ButtonActionContainer}:hover & {
    color: ${Colors.white};
    font-weight: bold;
  }
`;

export const ButtonActionText = styled.p`
  color: ${(props: IProps) => props.color};
  font-size: 15px;
  font-weight: ${(props: IProps) => props.bold ? 'bold' : 'normal'};
  text-transform: uppercase;
  text-align: center;
  transition: all 0.30s;

  ${GetPlatform() === 'WEB' && ButtonTextHover}
`;


export const ButtonChildrenAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
