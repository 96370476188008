import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import ButtonAccess from '../../../components/Buttons/ButtonChoose';
import ButtonWhatsApp from '../../../components/Buttons/ButtonWhatsApp';

import ContentAbout from '../../../components/Containers/ContentAbout';
import LandingHeader from '../../../components/Containers/LandingHeader';
// import ContentBusinessPlans from '../../../components/Contents/ContentBusinessPlans';
import FooterApp from '../../../components/Footers/FooterApp';
import NavbarLanding from '../../../components/Navbar/NavbarLanding';
import TreeScreens from '../../../components/TreeScreens';

import {
  openHelpWhatsApp,
  openSite,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  Images,
  Videos,
} from '../../../shared/constants';

import Vars from '../../../shared/environments/variables';

import SectionPlans from './SectionPlans';
import SectionProOffers from './SectionProOffers';
import SectionProTools from './SectionProTools';
import SectionProWhoUse from './SectionProWhoUse';

import {
  LandingAboutContent,
  LandingOrganizerLogoView,
  LandingOrganizerImage,
  LandingOrganizerViews,
  LandingOrganizerTitle,
  LandingOrganizerDescription,
  LandingOrganizerButton,
} from './styled';



const ProLandingScreen: React.FC = () => {
  const history = useHistory();


  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_initial',
    },
    {
      navTitle: 'Contato',
      navHref: '#contact',
    },
    {
      navTitle: 'Sobre nós',
      navHref: '#about_us',
    },
  ];


  function openAccoutOrganizerRegister() {
    openSite(Vars().contaOrganizerRegister);
  }


  async function onOpenHowToWorks() {
    history.push(NameRoutes.OrganizerDetailsLandingScreen);
  }


  async function contactAction() {
    openHelpWhatsApp('Olá, gostaria de mais informações sobre o PRO da Noitada e como personalizar um plano');
  }



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <ButtonWhatsApp />



      <LandingHeader
        id={'page_initial'}
        video={Videos.landingProVideo}>
        <LandingAboutContent>

          <LandingOrganizerLogoView>
            <LandingOrganizerImage
              src={Images.logoPROIconColor}
            />
          </LandingOrganizerLogoView>



          <LandingOrganizerViews>

            <LandingOrganizerTitle>
              O PRO da Noitada
            </LandingOrganizerTitle>


            <LandingOrganizerDescription>
              Ferramentas para te auxiliar nas vendas e te ajuda a organizar, acompanhar seu evento em tempo real.
            </LandingOrganizerDescription>


            <LandingOrganizerButton>
              <ButtonAccess
                text={'Criar conta grátis'}
                onPress={() => {
                  openAccoutOrganizerRegister();
                }}
              />
            </LandingOrganizerButton>

          </LandingOrganizerViews>

        </LandingAboutContent>
      </LandingHeader>



      <SectionProTools />



      <SectionPlans />



      {/*
      Falar das taxas de delivery
      <ContentBusinessPlans
        id={'prices'}
        buttonText={'Criar conta grátis'}
      />
      */}



      <SectionProOffers />



      <SectionProWhoUse />



      <ContentAbout
        id={'contact'}
        title={'Soluções personalizadas'}
        subtitle={'Entendemos suas necessidades, elaboramos melhores estratégias para suas operações e fazemos acontecer!'}
        backgroundImage={Images.backgroundPROSolutions}
        buttonOneText={'Saiba como funciona'}
        buttonOnePress={() => {
          onOpenHowToWorks();
        }}
        buttonTwoText={'Entre em contato'}
        buttonTwoPress={() => {
          contactAction();
        }}
      />



      <TreeScreens id={'about_us'} />
      <FooterApp />

    </NavbarLanding>

  );
};



export default ProLandingScreen;
