import styled from 'styled-components';



export const TermsFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  background-color: #222222;
`;



export const FooterText = styled.p`
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  text-align: center;
`;
