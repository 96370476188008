import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  SubTitleContents,
  TitleContents,
} from '../../../styles/styled.layout';



export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background: ${Colors.linearTopPrimary};
  background: linear-gradient(${Colors.linearTopPrimary}, ${Colors.linearBottomPrimary});
  background: -webkit-linear-gradient(${Colors.linearTopPrimary}, ${Colors.linearBottomPrimary});

  @media ${PlatformDevices.maxMobileL} {
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }

  @media ${PlatformDevices.minMobileL} {
    padding-left: ${Sizes.desktopPaddingHorizontal};
    padding-right: ${Sizes.desktopPaddingHorizontal};
  }
`;


export const DownloadTitle = styled(TitleContents)`
  text-align: center;
`;

export const DownloadSubTitle = styled(SubTitleContents)`
  margin-top: 10px;
  text-align: center;
`;


export const MarketContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${Sizes.marginFromTitles};
  
  @media ${PlatformDevices.maxMobileL} {
    flex-direction: column;
    gap: 15px;
  }
`;



export const MarketLinks = styled.a`
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  transition: all 0.35s;

  @media ${PlatformDevices.minLaptop} {
    :hover {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
`;

export const MarketImage = styled.img`
  height: 55px;
  transition: all 0.20s;

  @media ${PlatformDevices.minLaptop} {
    ${MarketLinks}:hover & {
      transform: scale(1.25);
    }
  }
`;



export const DownloadSeeAppsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SeparatorHorizontalImage = styled.img`
  height: 65px;
  width: 25%;
  min-width: 250px;
  margin-bottom: -10px;
`;

export const SeeAppsButton = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  text-align: center;
  transition: all 0.20s;
  cursor: pointer;

  @media ${PlatformDevices.maxMobileL} {
    margin-left: 5px;
    margin-right: 5px;
  }

  @media ${PlatformDevices.minLaptop} {
    :hover {
      color: ${Colors.black};
    }
  }
`;
