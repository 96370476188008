import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  TeamAllContainer,
  TeamAllTitlesView,
  TeamAllTitle,
  TeamAllContainerImage,
  TeamAllImage,
} from './styled';



export interface IProps {
  id?: string;
  title: string;
}



const ContentAllTeam: React.FC<IProps> = (props) => {
  return (

    <TeamAllContainer
      id={props.id}>

      <TeamAllTitlesView>
        <TeamAllTitle>
          {props.title}
        </TeamAllTitle>
      </TeamAllTitlesView>


      <TeamAllContainerImage>
        <TeamAllImage
          src={Images.teamAll}
        />
      </TeamAllContainerImage>

    </TeamAllContainer>

  );
};



export default ContentAllTeam;
