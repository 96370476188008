export const APPSITES = {
  contaGlobalApp:                   'https://conta.noitada.app',
  contaOrganizerRegister:           'https://conta.noitada.app/register-user',

  noitadaSite:                      'https://noitada.net',

  appNoitadaSite:                   'https://noitada.app',

  organizerNoitadaSite:             'https://organizer.noitada.app',

  backstageNoitadaSite:             'https://backstage.noitada.app',

  storeNoitadaSite:                 'https://store.noitada.app',
};



const Vars = {
  ...APPSITES,
};



export default Vars;
