import React from 'react';

import {
  Content,
  Screen,
  Toolbar,
} from '../../components/Composh/web';

import FooterApp from '../../components/Footers/FooterApp';

import {
  IconSocialWhatsApp,
} from '../../components/Icons';

import {
  openHelpWhatsApp,
} from '../../config/linking.config';

import {
  Colors,
  Images,
  Keys,
} from '../../shared/constants';

import {
  LinkBioHeaderContainer,
  // LinkBioHeaderBackground,
  LinkBioInfosContent,
  LinkBioLogoHeader,
  LinkBioInfoView,
  LinkBioLogoImage,
  LinkBioAppName,
  LinkBioSocialView,
  LinkSocialIconButton,
  LinkSocialIconImage,
  LinkBioContent,
  LinkBioDescriptionView,
  LinkBioDescriptionText,
  LinkBioContentButton,
  LinkBioContentIcon,
  LinkBioContentText,
  LinkBioFooter,
  Logocreateprofile,
  LogoProfileImage,
  OnPrimaryText,
} from './styled';



const SocialLinks: React.FC = () => {
  function renderSocialButton(name: string, logo: string, link: string) {
    return (

      <LinkSocialIconButton
        target={'_blank'}
        href={link}>

        <LinkSocialIconImage
          alt={name}
          title={name}
          src={logo}
        />

      </LinkSocialIconButton>

    );
  }


  function renderContentButton(icon: any, title: string, onPress: any) {
    return (

      <LinkBioContentButton
        onClick={() => {
          if (onPress) {
            onPress();
          }
        }}>

        <LinkBioContentIcon>
          {icon}
        </LinkBioContentIcon>


        <LinkBioContentText>
          {title}
        </LinkBioContentText>

      </LinkBioContentButton>

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Toolbar
        backgroundColor={Colors.toolbarBackground}
        centerContent={
          <LinkBioLogoHeader
            src={Images.logoNoitadaFullWhite}
          />
        }
      />



      <Content>

        <LinkBioHeaderContainer>

          {/*
          <LinkBioHeaderBackground
          />
          */}



          <LinkBioInfosContent>

            <LinkBioInfoView>

              <LinkBioLogoImage
                alt={'Logo'}
                title={'Logo'}
                src={Images.logoNoitadaAppIcon}
              />


              <LinkBioAppName>
                Noitada Brasil
              </LinkBioAppName>

            </LinkBioInfoView>



            <LinkBioSocialView>

              {renderSocialButton(
                'Facebook',
                Images.socialLogoFacebook,
                Keys.facebookLink,
              )}


              {renderSocialButton(
                'Instagram',
                Images.socialLogoInstagram,
                Keys.instagramLink,
              )}


              {renderSocialButton(
                'X (Twitter)',
                Images.socialLogoTwitter,
                Keys.twitterLink,
              )}


              {renderSocialButton(
                'LinkedIn',
                Images.socialLogoLinkedIn,
                Keys.linkedInLink,
              )}


              {renderSocialButton(
                'Youtube',
                Images.socialLogoYoutube,
                Keys.youtubeLink,
              )}


              {renderSocialButton(
                'E-mail',
                Images.socialLogoEmail,
                Keys.noitadaEmailContact,
              )}

            </LinkBioSocialView>

          </LinkBioInfosContent>

        </LinkBioHeaderContainer>



        <LinkBioContent>

          <LinkBioDescriptionView>

            <LinkBioDescriptionText>
              Curte sair, bares ou shows, mas não sabe onde ir? A Noitada é para você.
              {'\n'}
              #noitada
              {'\n'}
              {'\n'}
              Seja nosso parceiro, grátis! Contate-nos.
            </LinkBioDescriptionText>

          </LinkBioDescriptionView>



          {renderContentButton(
            <IconSocialWhatsApp
              color={Colors.white}
              size={28}
            />,
            'WhatsApp',
            () => {
              openHelpWhatsApp();
            },
          )}

        </LinkBioContent>



        <LinkBioFooter>

          <Logocreateprofile>

            <LogoProfileImage
              alt={'Logo'}
              src={Images.logoNoitadaIconWhite}
            />

          </Logocreateprofile>


          <OnPrimaryText>
            Para curtir a noite!
          </OnPrimaryText>

        </LinkBioFooter>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default SocialLinks;
