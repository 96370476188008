import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import ButtonAccess from '../../components/Buttons/ButtonChoose';
import HelpHeader from '../../components/Containers/HelpHeader';
import SocialButtons from '../../components/Containers/SocialButtons';
import FooterTerms from '../../components/Footers/FooterTerms';

import {
  IconNoitada,
  IconEmail,
} from '../../components/Icons';

import NavbarLanding from '../../components/Navbar/NavbarLanding';

import {
  openSite,
} from '../../config/linking.config';

import NameRoutes from '../../navigation/names';

import {
  Colors,
  Images,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import {
  HelpContentSegments,
  HelpFormContactView,
  HelpEmailItem,
  HelpEmailIcon,
  HelpEmailTitle,
  HelpEmailDescription,
  HelpEmailDescButton,
  HelpValueContainer,
  HelpEmailValue,
  HelpFormContactRight,
  HelpFormCreateTitle,
  HelpFormCreateButton,
} from './styled';



const ContactScreen: React.FC = () => {
  const history = useHistory();


  const navItemsPage = [
    // {
    //   navTitle: 'Central de ajuda',
    //   navHref: NameRoutes.HelpCenterScreen,
    // },
    {
      navTitle: 'Termos e Políticas',
      navHref: NameRoutes.TermsPoliciesScreen,
    },
  ];


  function renderEmailItem(icon: any, title: string, description: any, value: any, onPress?: any) {
    return (

      <HelpEmailItem>

        <HelpEmailIcon>
          {icon}
        </HelpEmailIcon>


        <HelpEmailTitle>
          {title}
        </HelpEmailTitle>


        {typeof description === 'string'
          ? (
            <HelpEmailDescription>
              {description}
            </HelpEmailDescription>
          )
          : description
        }


        <HelpValueContainer>
          {typeof value === 'string'
            ? (
              <HelpEmailValue
                onClick={onPress}>
                {value}
              </HelpEmailValue>
            )
            : value
          }
        </HelpValueContainer>

      </HelpEmailItem>

    );
  }



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <HelpHeader
        title={'Contato'}
        description={'Como você prefere falar com a gente?'}
        backgroundImage={Images.backgroundIndex2}
      />



      <HelpContentSegments>

        <HelpFormContactView>
          {renderEmailItem(
            <IconEmail
              color={Colors.primary}
              size={50}
            />,
            'E-mail',
            'Tem alguma dúvida? Podemos te ajudar pelo nosso canal de email.',
            'noitadabra@gmail.com',
          )}


          {renderEmailItem(
            <IconEmail
              color={Colors.primary}
              size={50}
            />,
            'Telefone',
            'Você pode entrar em contato a qualquer hora através do número abaixo.',
            '+55 71 9274-2085',
          )}


          {renderEmailItem(
            <IconEmail
              color={Colors.primary}
              size={50}
            />,
            'Quer trabalhar na Noitada?',
            'Dá uma olhana nas nossa vagas abertas.',
            'Clique aqui!',
          )}


          {renderEmailItem(
            <IconNoitada
              color={Colors.primary}
              size={40}
            />,
            'Siga a Noitada',
            <HelpEmailDescription>
              Novidades e dicas nas nossas redes sociais.
              {/* Há também nossa <a href='#'>comunidade</a> online e oficial. */}
            </HelpEmailDescription>,
            <SocialButtons />,
          )}


          {renderEmailItem(
            <IconNoitada
              color={Colors.primary}
              size={40}
            />,
            'Imprensa',
            <HelpEmailDescription>
              Reunimos todas as informações para vocês
              {' '}
              <HelpEmailDescButton
                onClick={() => {
                  // TODO
                  // history.push(NameRoutes.PressScreen);
                }}>
                aqui
              </HelpEmailDescButton>.
            </HelpEmailDescription>,
            'noitadabra@gmail.com',
          )}


          {renderEmailItem(
            <IconNoitada
              color={Colors.primary}
              size={40}
            />,
            'Parcerias',
            'Se você tem uma proposta de patrocínio, parceria ou publicidade, fale conosco por e-mail.',
            'noitadabra@gmail.com',
          )}

        </HelpFormContactView>



        <HelpFormContactRight>

          <HelpFormCreateTitle>
            Você pode começar a utilizar a Noitada agora mesmo!
          </HelpFormCreateTitle>

          <HelpFormCreateButton>
            <ButtonAccess
              text={'Acessar agora'}
              color={Colors.primary}
              onPress={() => {
                openSite(Vars().appNoitadaSite);
              }}
            />
          </HelpFormCreateButton>

        </HelpFormContactRight>



        <HelpFormContactRight>

          <HelpFormCreateTitle>
            Se deseja criar um perfil como parceiro, é totalmente gratuito!
          </HelpFormCreateTitle>

          <HelpFormCreateButton>
            <ButtonAccess
              text={'Criar conta grátis'}
              color={Colors.primary}
              onPress={() => {
                openSite(Vars().contaOrganizerRegister);
              }}
            />
          </HelpFormCreateButton>

        </HelpFormContactRight>

      </HelpContentSegments>



      <FooterTerms />

    </NavbarLanding>

  );
};



export default ContactScreen;
