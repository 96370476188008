import React from 'react';

import {
  openHelpWhatsApp,
} from '../../../config/linking.config';

import {
  Colors,
} from '../../../shared/constants';

import {
  IconSocialWhatsApp,
} from '../../Icons';

import {
  WhatsAppContainerButton,
  WhatsAppButtonText,
} from './styled';



export interface IProps {
  title?: string;
  onPress?: any;
  offsetBottom?: any;
}



const ButtonWhatsApp: React.FC<IProps> = (props: IProps) => {
  function onButtonPress() {
    if (props.onPress) {
      props.onPress();
      return;
    }

    openHelpWhatsApp();
  }



  return (

    <WhatsAppContainerButton
      onClick={() => {
        onButtonPress();
      }}>

      <IconSocialWhatsApp
        color={Colors.white}
        size={24}
      />


      <WhatsAppButtonText>
        {props.title || 'Entre em contato'}
      </WhatsAppButtonText>

    </WhatsAppContainerButton>

  );
};



export default ButtonWhatsApp;
