import styled from 'styled-components';

import {
  Link,
} from 'react-router-dom';

import {
  Colors,
} from '../../../shared/constants';



export const FooterRightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70px;
  min-height: 70px;
  background-color: #222222;
  text-align: center;
  justify-content: center;
`;

export const RightsTextP = styled.p`
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
`;

export const ListInline = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0;
`;

export const ListButton = styled(Link)`
  display: flex;
  padding-right: 7px;
  padding-left: 7px;
`;

export const ListFooterText = styled.p`
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  text-decoration: none;
  transition: all 0.20s;

  ${ListButton}:hover & {
    color: ${Colors.accent};
  }
`;
