import React from 'react';



const TermsNoitadaLicenseScreen: React.FC = () => {
  return (

    <body style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      color: 'black',
    }}>


      <h1>NOITADA</h1>

      <h2>LICENÇAS</h2>


      A seguir estão descritas as regras aplicáveis às licenças do aplicativo Noitada disponibilizado pela NOITADA LTDA. (doravante “NOITADA”) em aplicação web e dispositivos móveis, tais como sistemas Android, iOS e Windows Phone.

      Todos os materiais relacionados a Noitada e nos aplicativos e/ou sites disponibilizados pelo NOITADA, incluindo mas sem se limitar a textos, vídeos, formatos, fotografias, marcas, patentes, layout, experiência de navegação, designs, aplicativos, interfaces de aplicativos, softwares, fragmentos de softwares (doravante designados coletivamente ou em relação a qualquer parcela destes como “Conteúdo”) são protegidos pela legislação brasileira, em especial pela Lei de Direitos Autorais (Lei Federal 9.610/98) e Lei de Propriedade Industrial (Lei Federal 9.279/96).


      1.	Isenção e segurança

      O NOITADA não se responsabiliza por Hyperlinks dos sites/ambientes externos vinculados ou as informações, produtos ou serviços ali contidos.
      Ao utilizar o Conteúdo de qualquer forma, você expressa sua aceitação plena e sem reservas aos Termos tal como vigentes naquele instante, para todos os fins de direito.

      2.	Bloqueio, suspensão e exclusão

      A fim de otimizar a disponibilização do Conteúdo, o Noitada poderá, inclusive por questões de segurança, restringir, limitar e realizar a suspensão ou bloqueios, totais ou parciais, de disponibilização e acesso ao Conteúdo.

      O Noitada poderá descontinuar a disponibilidade ou distribuição de qualquer Conteúdo, a qualquer tempo e sem necessidade de prévio aviso.


      3.	Conteúdo exibido

      Todo o conteúdo exibido é referente aos parceiros e colaboradores da Noitada. As informações coletadas e exibidas pelo Noitada são de total responsabilidade dos parceiros e colaboradores.  O NOITADA apenas reúne as informações e divulga a partir do aplicativo Noitada e não se responsabiliza pelos mesmos



      Se você ainda possui alguma dúvida sobre a forma de utilização de nosso produto ou sobre o nosso termo de uso ou nossa política de privacidade e seus direitos e deveres perante o aplicativo Noitada, poderá entrar em contato a qualquer momento.


    </body>


  );
};



export default TermsNoitadaLicenseScreen;
