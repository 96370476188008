import React from 'react';

import ButtonAccess from '../../Buttons/ButtonChoose';

import {
  AboutContent,
  ContentOverlay,
  ImageAbout,
  AboutContentTitle,
  AboutContentDescription,
  AboutButtonViews,
  OverlayView,
} from './styled';



export interface IProps {
  id?: string;

  image?: string;
  title?: string;
  subtitle?: string;

  buttonOneText?: string;
  buttonOnePress?: any;
  buttonTwoText?: string;
  buttonTwoPress?: any;

  backgroundImage?: any;
}



const ContentAbout: React.FC<IProps> = (props: any) => {
  function renderButton(buttonFunctionText: string, buttonFunctionPress: any) {
    return buttonFunctionText && (
      <ButtonAccess
        color={props.titleColor}
        text={buttonFunctionText}
        onPress={buttonFunctionPress}
      />
    );
  }



  return (

    <AboutContent
      id={props.id}
      style={{
        backgroundImage: 'url(' + props.backgroundImage + ')',
      }}>

      <ContentOverlay>

        {props.image && (
          <ImageAbout
            src={props.image}
          />
        )}

        <AboutContentTitle>
          {props.title.toUpperCase()}
        </AboutContentTitle>


        <AboutContentDescription>
          {props.subtitle}
        </AboutContentDescription>


        {(props.buttonOneText || props.buttonTwoText) && (
          <AboutButtonViews>
            {renderButton(props.buttonOneText, props.buttonOnePress)}
            {renderButton(props.buttonTwoText, props.buttonTwoPress)}
          </AboutButtonViews>
        )}

      </ContentOverlay>


      <OverlayView />

    </AboutContent>

  );
};



export default ContentAbout;
