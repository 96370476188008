import React from 'react';



const TermsOrganizerPrivacityScreen: React.FC = () => {
  return (

    <body style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      color: 'black',
    }}>

      <h1>
        NOITADA ORGANIZER
      </h1>

      <h2>POLITICA DE PRIVACIDADE</h2>


      A seguir estão descritas as regras aplicáveis à privacidade do aplicativo Noitada disponibilizado pelo NOITADA LTDA. (doravante “NOITADA”) em aplicação web e dispositivos móveis, tais como sistemas Android, iOS e Windows Phone.

      Todas as suas informações pessoais recolhidas, serão usadas apenas para acessar os serviços prestado pelo aplicativo Noitada e para o ajudar a tornar o aplicativo e/ou site o mais produtivo e agradável possível.

      Todas as informações pessoais relativas a usuários, patrocinadores, clientes ou visitantes que usem o Noitada serão tratadas em concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n. º 67/98).

      O uso da Noitada pressupõe a aceitação deste Acordo de privacidade. A equipe da Noitada reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.


      1.	Informações obtidas

      O Noitada coleta informações de diversas formas. Algumas informações pessoais são obtidas na tela de registro. Não é permitido registros que contenham:

      •	Palavras, expressões ou conjuntos gráficos denominativos que já tenham sido escolhidos anteriormente por outros usuários.

      •	Palavras injuriosas, difamatório, obsceno ou ilícito, incluindo-se informações e conteúdo cujos direitos autorais pertençam a terceiros, coincidentes com marcas de produtos ou serviços de terceiros e, em geral, contrários à lei, à ordem ou às exigências da moral e dos bons costumes comumente aceitos.

      O Noitada possui diversos parceiros e/ou patrocinadores, que também criam sorteios e promoções, oferecendo inúmeros bens e/ou serviços através do aplicativo e/ou sites que são acessados dentro da Noitada, além das redes sociais vinculados a NOITADA. As informações fornecidas quando visita e/ou completa transações nesses sites são obtidas por esses parceiros e/ou patrocinadores, estando assim sujeitas às suas próprias práticas de obtenção e uso de dados, sem que caiba qualquer responsabilidade à NOITADA pelo manuseio de tais informações.

      As informações pessoais recolhidas podem incluir nome, data de nascimento, e-mail, número de telefone, redes sociais, morada, data de nascimento e/ou outros.


      2.	Os Cookies e Web Beacons

      Utilizamos cookies (pequenos arquivos de texto enviados ao seu computador que são armazenados no mesmo e que servem para reconhecer, acompanhar e armazenar a navegação do usuário na Internet) para armazenar informação, tais como as suas preferências pessoas quando visita o nosso site. Isto poderá incluir um simples pop-up, ou uma ligação em vários serviços que providenciamos, tais como fóruns.

      Em geral, os cookies são utilizados para proporcionar serviços diferenciados, lembrando quem é o usuário e quais são os seus hábitos de navegação, além de:

      •	Acessar as informações do cadastro do mesmo no Noitada (armazenado em nossos servidores);

      •	Calcular a dimensão da audiência da Noitada;

      •	Acompanhar o andamento de promoções organizadas pelo Noitada, aos quais usam cookie e as informações gravadas no cookie indicam a pontuação do usuário;

      •	Facilitar e agilizar o preenchimento de formulários. As informações contidas nos cookies de cada usuário podem ser utilizadas para preencher previamente os formulários de coleta de dados existentes na Internet.



      Em adição também utilizamos publicidade de terceiros no nosso site para suportar os custos de manutenção. Alguns destes publicitários, poderão utilizar tecnologias como os cookies e/ou web beacons quando publicitam no nosso site, o que fará com que esses publicitários (como o Google através do Google AdSense) também recebam a sua informação pessoal, como o endereço IP, nome, e-mail e/ou seu navegador.

      O usuário detém o poder de desligar os seus cookies, nas opções do seu navegador, ou efetuando alterações nas ferramentas de programas Antivírus. No entanto, isso poderá alterar a forma como interage no site da Noitada ou em outros. Isso poderá afetar ou não permitir que o usuário faça logins em programas, sites ou fóruns da rede da Noitada ou em outros.


      3.	Utilização das informações

      As informações coletadas pelo Noitada serão utilizadas para personalizar o conteúdo e/ou serviços disponibilizados. As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial.

      Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários da Noitada poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação. O NOITADA não tem como prática a divulgação de informações que possam identificá-lo e não comercializa informações de usuários em nenhuma hipótese.

      Para alguns tipos de promoções, solicitaremos seu endereço de e-mail para atualizá-lo sobre a sua pontuação, suas participações e status nas premiações. Ao participar desse tipo de promoção, você automaticamente recebe mensagens relacionadas à promoção. Ocasionalmente, essas mensagens de e-mail relacionadas à promoção podem incluir anúncios direcionados.



      4.	Precauções de segurança para sua proteção

      As informações sobre a conta da Noitada são protegidas por senha, para que somente você tenha acesso a suas informações pessoais. Sua senha é secreta e recomendamos que não a revele a ninguém. O NOITADA, ou a mesma vinculadas a outras plataformas não irá solicitar, em nenhuma hipótese, seja por e-mail ou telefone, sua senha pessoal.

      Ao utilizar o site, o usuário deve desconectar-se da sua conta da Noitada e fechar a janela do seu navegador quando concluir sua navegação na Internet. Isso garante que não tenham acesso às suas informações pessoais e correspondências, caso o usuário compartilhe um computador com outras pessoas ou esteja usando um computador em local público como uma biblioteca ou local de acesso público à Internet.

      Nenhuma transmissão de dados na Internet é 100% segura. Sendo assim, embora o NOITADA sempre faça o possível para proteger suas informações pessoais, não é possível garantir a segurança de todas as informações que o usuário venha a fornecer. É uma decisão pessoal a utilização do serviço nessas condições.

      Em última análise o usuário é o responsável pela manutenção de sua privacidade e pelo sigilo de suas senhas e/ou informações pessoais.


      5.	Ligações de sites de terceiros

      O NOITADA possui ligações para outros sites, os quais, a nosso entendimento, podem conter informações e/ou ferramentas úteis para os nossos usuários. A política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir dos domínios da Noitada deverá ler a política de privacidade do mesmo, logo não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.



      Se você ainda possui alguma dúvida sobre a forma de utilização de nosso produto ou sobre o nosso termo de uso ou nossa política de privacidade e seus direitos e deveres perante o aplicativo Noitada, poderá entrar em contato a qualquer momento.

    </body>


  );
};



export default TermsOrganizerPrivacityScreen;
