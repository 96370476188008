import React from 'react';

import {
  openSite,
} from '../../../config/linking.config';

import {
  Images,
} from '../../../shared/constants';

import {
  DownloadContainer,
  DownloadTitle,
  DownloadSubTitle,
  MarketContainer,
  MarketLinks,
  MarketImage,
  DownloadSeeAppsView,
  SeparatorHorizontalImage,
  SeeAppsButton,
} from './styled';



interface IProps {
  id?: string;
  showOtherApps?: boolean;

  title: string;
  description?: string;

  linkGooglePlay?: string;
  linkAppStore?: string;
}



const DownloadApp: React.FC<IProps> = (props) => {
  return (

    <DownloadContainer
      id={props.id}>

      <DownloadTitle>
        {props.title ? props.title.toUpperCase() : '-'}
      </DownloadTitle>


      {props.description && (
        <DownloadSubTitle>
          {props.description}
        </DownloadSubTitle>
      )}


      <MarketContainer>

        {props.linkGooglePlay && (
          <MarketLinks
            onClick={() => {
              openSite(props.linkGooglePlay);
            }}>
            <MarketImage
              alt={''}
              src={Images.marketGooglePlay}
            />
          </MarketLinks>
        )}


        {props.linkAppStore && (
          <MarketLinks
            onClick={() => {
              openSite(props.linkAppStore);
            }}>
            <MarketImage
              alt={''}
              src={Images.marketAppStore}
            />
          </MarketLinks>
        )}

      </MarketContainer>



      {props.showOtherApps && (
        <DownloadSeeAppsView>
          <SeparatorHorizontalImage
            alt={''}
            src={Images.separatorHorizontal}
          />

          <SeeAppsButton
            onClick={() => {
              // TODO
            }}>
            Ou conheça os outros aplicativos da Noitada
          </SeeAppsButton>
        </DownloadSeeAppsView>
      )}

    </DownloadContainer>

  );
};



export default DownloadApp;
