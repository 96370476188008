import React from 'react';

import {
  Svgs,
} from '../../../shared/constants';

import {
  EConContainer,
  ElementorContainer,
  EWidgetContainer,
  EWidgetImage,
  EWidgetOverlay,
  EConPlayButton,
  EConPlayImageutton,
  EConInfoContainer,
  EConInfoSubTitle,
  EConInfoTitle,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionAboutPress: React.FC<IProps> = (props) => {
  return (

    <EConContainer
      id={props.id}>

      <ElementorContainer>

        <EWidgetContainer>

          <EWidgetImage
            alt=""
            src={'https://img.freepik.com/fotos-gratis/empresarios-de-pe-fora-do-predio-de-escritorios_107420-74307.jpg'}
          />


          <EWidgetOverlay>

            <EConPlayButton>
              <EConPlayImageutton
                alt=""
                src={Svgs.playButton}
              />
            </EConPlayButton>

          </EWidgetOverlay>

        </EWidgetContainer>

      </ElementorContainer>



      <EConInfoContainer>

        <EConInfoSubTitle>
          Ei, que tal
        </EConInfoSubTitle>


        <EConInfoTitle>
          Conhecer um pouco mais da Noitada?
        </EConInfoTitle>
      </EConInfoContainer>

    </EConContainer>


  );
};



export default SectionAboutPress;
