export const APPSITES = {
  contaGlobalApp:                   'http://localhost:1000',
  contaOrganizerRegister:           'http://localhost:1000/register-user',

  noitadaSite:                      'http://localhost:4000',

  appNoitadaSite:                   'http://localhost:4001',

  organizerNoitadaSite:             'http://localhost:4002',

  backstageNoitadaSite:             'http://localhost:4003',

  storeNoitadaSite:                 'http://localhost:4005',
};



const Vars = {
  ...APPSITES,
};



export default Vars;
