import React from 'react';

import LandingHeader from '../LandingHeader';

import {
  LandingAboutContent,
  AboutHeaderContainer,
  AboutHeaderTitle,
  AboutHeaderText,
} from './styled';



interface IProps {
  id: string;
  video?: any;
  title: string;
  description?: string;
}



const LandingAboutScreen: React.FC<IProps> = (props: IProps) => {
  return (

    <LandingHeader
      id={props.id}
      video={props.video}>
      <LandingAboutContent>

        <AboutHeaderContainer>

          <AboutHeaderTitle>
            {props.title}
          </AboutHeaderTitle>


          {props.description && (
            <AboutHeaderText>
              {props.description}
            </AboutHeaderText>
          )}

        </AboutHeaderContainer>

      </LandingAboutContent>
    </LandingHeader>

  );
};



export default LandingAboutScreen;
