const environment = {
  production: false,
  environment: 'LOCAL',
  title: 'Local Environment Heading',
  apiUrl: 'http://localhost:3333',
  firebaseWebConfig: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};



export default environment;
