import React from 'react';

import {
  useLocation,
} from 'react-router-dom';

import {
  Images,
} from '../../../shared/constants';

import TermsPoliciesContainer from '../Container';



const TermsNoitadaPrivacityScreen: React.FC = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const paramToolbar = urlParams.get('toolbar');
  const showToolbar = paramToolbar && paramToolbar === 'false' ? false : true;


  const policyObject = {
    intro: {
      title: 'Introdução',
      href: 'introduction',
      updateAt: '03/04/2024', // DD/MM/YYYY
      modifieds: [''],
      paragraphs: [
        {
          text: 'Olá consumidor',
        },
        {
          text: 'Estes são os termos que determinarão nossa relação. Antes de utilizar o app da Noitada, é necessário que você leia esta política e, ao utilizar o app da Noitada, você concorda com essa política.',
        },
      ],
    },
    data: [
      {
        title: 'Aceite',
        href: 'accept',
        paragraphs: [
          {
            text: 'A seguir estão descritas as regras aplicáveis à privacidade do app da Noitada disponibilizado pela NOITADA LTDA. (doravante “NOITADA”) em aplicação web e dispositivos móveis, tais como sistemas Android e iOS.',
          },
          {
            text: 'Todas as suas informações pessoais recolhidas, serão usadas apenas para acessar os serviços prestado pelo app da Noitada e para o ajudar a tornar a aplicação mais produtiva e agradável possível.',
          },
          {
            text: 'Todas as informações pessoais relativas a usuários, patrocinadores, clientes ou visitantes que usem o app da Noitada serão tratadas em concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n. º 67/98).',
          },
          {
            text: 'O uso do app da Noitada pressupõe a aceitação deste Acordo de privacidade. A equipe da Noitada reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte esta política de privacidade com regularidade de forma a estar sempre atualizado.',
          },
        ],
      },
      {
        title: 'Informações obtidas',
        href: 'accept-terms',
        paragraphs: [
          {
            text: 'O app da Noitada coleta informações de diversas formas. Algumas informações pessoais são obtidas na tela de registro. Não é permitido registros que contenham:',
            submenu: [
              {
                type: 'DOT',
                text: 'Palavras, expressões ou conjuntos gráficos denominativos que já tenham sido escolhidos anteriormente por outros usuários',
              },
              {
                type: 'DOT',
                text: 'Palavras injuriosas, difamatório, obsceno ou ilícito, incluindo-se informações e conteúdo cujos direitos autorais pertençam a terceiros, coincidentes com marcas de produtos ou serviços de terceiros e, em geral, contrários à lei, à ordem ou às exigências da moral e dos bons costumes comumente aceitos.',
              },
            ],
          },
          {
            text: 'A Noitada possui diversos parceiros e/ou patrocinadores, que também criam sorteios e promoções, oferecendo inúmeros bens e/ou serviços através do aplicativo e/ou sites que são acessados dentro da Noitada, além das redes sociais vinculados a NOITADA. As informações fornecidas quando visita e/ou completa transações nesses sites são obtidas por esses parceiros e/ou patrocinadores, estando assim sujeitas às suas próprias práticas de obtenção e uso de dados, sem que caiba qualquer responsabilidade à NOITADA pelo manuseio de tais informações.',
          },
          {
            text: 'As informações pessoais recolhidas podem incluir nome, data de nascimento, e-mail, número de telefone, redes sociais, morada, data de nascimento e/ou outros.',
          },
        ],
      },
      {
        title: 'Os Cookies e Web Beacons',
        href: 'cookies-beacons',
        paragraphs: [
          {
            text: 'Utilizamos cookies (pequenos arquivos de texto enviados ao seu computador que são armazenados no mesmo e que servem para reconhecer, acompanhar e armazenar a navegação do usuário na Internet) para armazenar informação, tais como as suas preferências pessoas quando visita a aplicação para web e dispositivos móveis. Isto poderá incluir um simples pop-up, ou uma ligação em vários serviços que providenciamos, tais como fóruns.',
          },
          {
            text: 'Em geral, os cookies são utilizados para proporcionar serviços diferenciados, lembrando quem é o usuário e quais são os seus hábitos de navegação, além de:',
            submenu: [
              {
                type: 'DOT',
                text: 'Acessar as informações do cadastro do mesmo no Noitada (armazenado em nossos servidores);',
              },
              {
                type: 'DOT',
                text: 'Calcular a dimensão da audiência da Noitada;',
              },
              {
                type: 'DOT',
                text: 'Acompanhar o andamento de promoções organizadas pelo Noitada, aos quais usam cookie e as informações gravadas no cookie indicam a pontuação do usuário;',
              },
              {
                type: 'DOT',
                text: 'Facilitar e agilizar o preenchimento de formulários. As informações contidas nos cookies de cada usuário podem ser utilizadas para preencher previamente os formulários de coleta de dados existentes na Internet.',
              },
            ],
          },
          {
            text: 'Em adição também utilizamos publicidade de terceiros no nosso site para suportar os custos de manutenção. Alguns destes publicitários, poderão utilizar tecnologias como os cookies e/ou web beacons quando publicitam no nosso site, o que fará com que esses publicitários (como o Google através do Google AdSense) também recebam a sua informação pessoal, como o endereço IP, nome, e-mail e/ou seu navegador.',
          },
          {
            text: 'O usuário detém o poder de desligar os seus cookies, nas opções do seu navegador, ou efetuando alterações nas ferramentas de programas Antivírus. No entanto, isso poderá alterar a forma como interage no site da Noitada ou em outros. Isso poderá afetar ou não permitir que o usuário faça logins em programas, sites ou fóruns da rede da Noitada ou em outros.',
          },
        ],
      },
      {
        title: 'Utilização das informações',
        href: 'use-infos',
        paragraphs: [
          {
            text: 'As informações coletadas pelo app da Noitada serão utilizadas para personalizar o conteúdo e/ou serviços disponibilizados. As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial.',
          },
          {
            text: 'Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários da Noitada poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação. A NOITADA não tem como prática a divulgação de informações que possam identificá-lo e não comercializa informações de usuários em nenhuma hipótese.',
          },
          {
            text: 'Para alguns tipos de promoções, solicitaremos seu endereço de e-mail para atualizá-lo sobre a sua pontuação, suas participações e status nas premiações. Ao participar desse tipo de promoção, você automaticamente recebe mensagens relacionadas à promoção. Ocasionalmente, essas mensagens de e-mail relacionadas à promoção podem incluir anúncios direcionados.',
          },
        ],
      },
      {
        title: 'Precauções de segurança para sua proteção',
        href: 'protect-segures',
        paragraphs: [
          {
            text: 'As informações sobre a conta da Noitada são protegidas por senha, para que somente você tenha acesso a suas informações pessoais. Sua senha é secreta e recomendamos que não a revele a ninguém. A NOITADA, ou a mesma vinculadas a outras plataformas não irá solicitar, em nenhuma hipótese, seja por e-mail ou telefone, sua senha pessoal.',
          },
          {
            text: 'Ao utilizar o site, o usuário deve desconectar-se da sua conta da Noitada e fechar a janela do seu navegador quando concluir sua navegação na Internet. Isso garante que não tenham acesso às suas informações pessoais e correspondências, caso o usuário compartilhe um computador com outras pessoas ou esteja usando um computador em local público como uma biblioteca ou local de acesso público à Internet.',
          },
          {
            text: 'Nenhuma transmissão de dados na Internet é 100% segura. Sendo assim, embora a NOITADA sempre faça o possível para proteger suas informações pessoais, não é possível garantir a segurança de todas as informações que o usuário venha a fornecer. É uma decisão pessoal a utilização do serviço nessas condições.',
          },
          {
            text: 'Em última análise o usuário é o responsável pela manutenção de sua privacidade e pelo sigilo de suas senhas e/ou informações pessoais.',
          },
        ],
      },
      {
        title: 'Ligações de sites de terceiros',
        href: 'protect-segures',
        paragraphs: [
          {
            text: 'A NOITADA e suas aplicações possui ligações para outros sites, os quais, a nosso entendimento, podem conter informações e/ou ferramentas úteis para os nossos usuários. A política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir dos domínios da Noitada deverá ler a política de privacidade do mesmo, logo não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.',
          },
        ],
      },
      {
        title: 'Dúvidas',
        href: 'answer-privacy',
        paragraphs: [
          {
            text: 'Se você ainda possui alguma dúvida sobre a forma de utilização do app da Noitada ou sobre o nosso termo de uso ou nossa política de privacidade e seus direitos e deveres perante aos aplicativos da Noitada, poderá entrar em contato a qualquer momento por qualquer canal disponível, como números de telefones, aplicativos de mensagens e redes sociais por exemplo.',
          },
        ],
      },
    ],
  };



  return (

    <TermsPoliciesContainer
      appType={'APP'}
      showToolbar={showToolbar}
      termsData={policyObject}
      title={'Noitada App: Política de privacidade'}
      backgroundImage={Images.appTermPoliciesPrivacity}
    />

  );
};



export default TermsNoitadaPrivacityScreen;
