import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
} from '../../../styles/styled.layout';



export const TeamAllContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  background-color: ${Colors.appBackground};
`;

export const TeamAllTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const TeamAllTitle = styled(TitleContents)`
`;



export const TeamAllContainerImage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
`;

export const TeamAllImage = styled.img`
  width: 100%;
  object-fit: contain;
`;
