import React,
{
  useEffect,
} from 'react';
import './App.css';

// import {
//   Provider,
// } from 'react-redux';

import * as PackageInfo from '../package.json';

import BadgeEnv from './components/Badges/BadgeEnv';

import Navigation from './navigation';

import {
  Colors,
  Keys,
} from './shared/constants';

// import store from './store';



const App: React.FC = () => {
  const titleProject = 'Noitada Website';
  const projectPackage = PackageInfo;

  useEffect(() => {
    const cssEmoji = `font-size: 50px; text-shadow: 2px 2px 0 ${Colors.primary}`;
    const cssText = `font-size: 17px; color: ${Colors.accent}`;
    console.log(`%c${'\ud83d\udc40'}${'\n'}%c${'Boa noite! Olhando nosso console?'}${'\n'}${'Poderia estar olhando nossas vagas em aberto, para nos ajudar a fazer uma mega Noitada'}${'\n'}${Keys.jobsLink}`, cssEmoji, cssText);

    console.log(`${titleProject} Version: ${projectPackage.version}`);
  }, []);



  return (

    // <Provider store={store}>
    <div>

      <BadgeEnv />

      <Navigation />

    </div>
    // </Provider>

  );
};



export default App;
