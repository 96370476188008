import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const TermsListInline = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0;
`;


export const TermsSocialButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.4);
  border-color: ${Colors.transparent};

  margin-top: 2.5px;
  margin-bottom: 2.5px;
  margin-left: 3.5px;
  margin-right: 3.5px;

  text-decoration: none;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;

  transition: all 0.20s;
  cursor: pointer;

  :hover {
    background-color: ${Colors.primaryDark};
    border-color: ${Colors.white};
  }
`;
