import styled from 'styled-components';



const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;



export default Body;
