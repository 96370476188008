import React from 'react';
import './styled.css';

import Error404Image from '../../shared/assets/images/error_404.png';



function Error404Screen() {
  return (

    <body className="body-container error-container error404-container" >
      <div className="exception-body-container">
        <div className="exception-container">
          <img src="https://www.primefaces.org/apollo-react/assets/layout/images/logo-dark.png" alt="apollo-layout" />
          <h1>Error 404</h1>
          <p>
            Desculpe, parece que ninguém está aqui :(
          </p>
          <a href="/" className="btn-to-click">Volte para o início</a>
        </div>
        <div className="exception-body-image">
          <img src={Error404Image} alt="apollo-layout" />
        </div>
      </div>
    </body>

  );
}



export default Error404Screen;
