import DEV_VARS from './variables.dev';
import LOCAL_VARS from './variables.local';
import PROD_VARS from './variables.prod';



const Vars = () => {
  const env = process.env.REACT_APP_NODE_ENV;

  switch (env) {
    case 'DEV': return DEV_VARS;
    case 'PROD': return PROD_VARS;
    default: return LOCAL_VARS;
  }
};



export default Vars;
