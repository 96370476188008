import React from 'react';

import AppRoutes from './routes/app.routes';



const Navigation: React.FC = () => {
  return (

    <AppRoutes />

  );
};



export default Navigation;
