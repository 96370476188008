/* eslint-disable react/no-unescaped-entities */
import React from 'react';



const TermsNoitadaUseScreen: React.FC = () => {
  return (

    <body style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      color: 'black',
    }}>

      <h1>NOITADA</h1>

      <h2>TERMOS E CONDIÇÕES GERAIS DE USO</h2>

      A seguir estão descritas as regras aplicáveis à utilização do aplicativo Noitada disponibilizado pelo NOITADA LTDA. (doravante “NOITADA”) em aplicação web e dispositivos móveis, tais como sistemas Android, iOS e Windows Phone.

      Os presentes termos e condições gerais para o uso do aplicativo nominado "Noitada" aplicam-se aos serviços prestados pelos proprietários dos direitos autorais do aplicativo estando protegidos pelas disposições de todas as leis e tratados internacionais sobre direitos autorais, em especial as Leis Federais brasileiras de nº 9.609/1998 e 9.610/1998, além do Código Civil Brasileiro, sendo vedada a utilização de qualquer conteúdo de propriedade intelectual do(s) acima indicados sem sua expressa autorização.


      1.	Aceite dos termos

      Ao realizar o cadastro para utilização do Aplicativo, o Usuário se submeterá automaticamente às regras e condições destes Termos de Uso.

      É proibida a realização de mais de um cadastro por Usuário, bem como, o Usuário se utilizar do cadastro de outro Usuário.

      O uso do aplicativo é restrito a usuários que detenham os direitos de sua capacidade civil, sendo que o usuário, ao concordar com os presentes TERMOS E CONDIÇÕES DE USO, declara:
      a) ser maior, na forma da legislação civil, e estar no pleno gozo de sua capacidade civil plena;
      b) não permitirá e nem fornecerá meios para que incapazes utilizem os serviços do aplicativo Noitada e dos sites que lhe sejam relacionados;
      c) que não utilizará o aplicativo Noitada ou acessará o conteúdo dos sites relacionados em locais cuja legislação defina-o como ilegal.

      O download do aplicativo Noitada, bem como o acesso aos serviços dos demais sites, portais, páginas e demais serviços disponibilizados ou prestados pelos proprietários de seus direitos autorais implica na aceitação de todos os termos e condições estabelecidos neste documento eletrônico, sendo que, havendo discordância, o usuário deverá:
      a) abster-se de promover o download do aplicativo em seu dispositivo de armazenamento e/ou apagá-lo, caso já haja feito a descarga do conteúdo em sua memória;
      b) abster-se de acessar qualquer conteúdo divulgado pelos proprietários dos direitos autorais do aplicativo, incluindo-se o conteúdo disponibilizado em seus sites, portais e demais meios de acesso.

      O Usuário autoriza o NOITADA, a utilizar, por prazo indeterminado, as informações fornecidas no ato do cadastro e durante o uso do aplicativo, para fins estatísticos e envio de material publicitário, newsletters, informes, etc.

      O usuário somente poderá utilizar os serviços prestados através do aplicativo Noitada, bem como de seu portal e site, para finalidades lícitas no Brasil e/ou na localidade a cuja jurisdição esteja submetido o usuário.


      2.	Diretrizes de Usuário

      O NOITADA respeita os direitos de propriedade intelectual e espera que você faça o mesmo. Abaixo estão algumas regras básicas para o usuário seguir ao usar o Serviço, para assegurar que o aplicativo Noitada permaneça justo para todos.

      O seguinte não é permitido de nenhuma maneira:

      •	O usuário não poderá publicar, enviar, distribuir ou divulgar conteúdo ou informação de caráter difamatório, obsceno ou ilícito, incluindo-se informações e conteúdo cujos direitos autorais pertençam a terceiros; marcas registradas ou protegidas por direitos autorais de terceiros; imagens que não estejam de acordo com a política interna dos autores e detentores de direitos autorais do aplicativo; imagens com conteúdo pornográficos, ilustrando o uso de armas de fogo, narcóticos, violência, pedofilia e/ou qualquer outro conteúdo protegido ou proibido por lei.

      •	Divulgar informações pessoais de terceiros, inclusive endereços na internet.

      •	Transferir cópias do Conteúdo armazenado em cache de um dispositivo autorizado para qualquer outro dispositivo através de quaisquer meios;

      •	Executar engenharia reversa, descompilar, desmontar, modificar ou criar trabalhos derivativos com base no aplicativo Noitada, no Conteúdo ou em qualquer parte deste, a menos que seja permitido pela lei aplicável;

      •	Burlar qualquer tecnologia usada pelo NOITADA, por seus licenciadores ou por qualquer terceiro para proteger o Conteúdo ou o Serviço;

      •	Vender, alugar, sublicenciar ou arrendar qualquer parte do aplicativo Noitada ou do Conteúdo;

      •	Aumentar artificialmente a contagem de pontos ou manipular de outra forma os Serviços usando um script ou outro processo automatizado;

      •	Remover ou alterar qualquer direito autoral, marca comercial ou outros avisos de propriedade intelectual contidos em ou fornecidos por meio do Serviço (inclusive com a finalidade de dissimular ou alterar quaisquer indicações de propriedade ou da origem de qualquer Conteúdo);

      •	Fornecer sua senha para qualquer outra pessoa ou usar o nome de usuário e a senha de outra pessoa;

      •	Vender a conta de um usuário, ou aceitar de outra forma qualquer remuneração, financeira ou outra, para influenciar o nome de uma conta, ou o conteúdo incluído em uma conta.



      Respeite o NOITADA, os proprietários do Conteúdo e outros usuários do aplicativo Noitada. Não se envolva em nenhuma atividade ou cadastre e/ou utilize um nome de usuário, que seja ou inclua material que:

      •	Seja ofensivo, abusivo, difamatório, pornográfico, ameaçador ou obsceno;

      •	Seja ilegal ou destinado a promover ou a cometer um ato ilegal de qualquer tipo, incluindo, entre outros, violações de direitos de propriedade intelectual, direitos de privacidade ou direitos proprietários do aplicativo Noitada ou de um terceiro;

      •	Inclua sua senha ou intencionalmente inclua a senha de qualquer outro usuário ou inclua intencionalmente dados pessoais de terceiros, ou seja, destinado a solicitar tais dados pessoais;

      •	Inclua conteúdo malicioso como malware, cavalos de Troia ou vírus, ou interfira de outra forma no acesso de qualquer usuário ao Serviço;

      •	Personifique ou deturpe sua afiliação com outro usuário, pessoa ou entidade, ou seja fraudulento, falso, enganoso ou ilusório de outra forma;

      •	Use meios automatizados para promover artificialmente o conteúdo;

      •	Envolva a transmissão de mala direta não solicitada ou outras formas de spam ("spam"), lixo eletrônico, correntes ou semelhantes, inclusive por meio da caixa de entrada da NOITADA ou do aplicativo Noitada;

      •	Envolva atividades comerciais ou de vendas, como propaganda, promoções, concursos, sorteios ou esquemas de pirâmide, que não sejam expressamente autorizados pela NOITADA;

      •	Interfira com, ou de alguma forma descontinue, o aplicativo Noitada, adultere, viole ou tente sondar, varrer ou testar as vulnerabilidades no Serviço ou nos sistemas de computação, rede e regras de utilização da Noitada, ou em qualquer um dos componentes de segurança, medidas de autenticação ou quaisquer outras medidas de proteção da Noitada aplicáveis ao Serviço, ao Conteúdo ou a qualquer parte dele; ou entre em conflito com os Acordos, conforme determinado pelo NOITADA.


      Você confirma e concorda que postar tal Conteúdo do Usuário poderá resultar em encerramento imediato ou suspensão da sua conta no aplicativo Noitada. Você também concorda que o NOITADA também poderá reivindicar seu nome de usuário por qualquer motivo.

      O NOITADA poderá, sem prévio aviso, bloquear e cancelar o acesso ao aplicativo quando verificar que o Usuário praticou algum ato ou mantenha conduta que:
      a) viole as leis e regulamentos federais, estaduais e/ou municipais;
      b) contrarie as regras destes Termos de Uso;
      c) viole os princípios da moral e dos bons costumes.

      Sua senha protege sua conta de usuário e você é o único responsável por manter sua senha confidencial e segura. Você compreende que é responsável por toda a utilização do seu nome de usuário e senha no Serviço. Se seu nome de usuário ou senha for perdido ou roubado, ou se você acreditar que tenha havido acesso não autorizado à sua conta por terceiros, informe-nos imediatamente e altere sua senha o mais rápido possível.

      O usuário se compromete a notificar a administração da Noitada, através de formulários ou e-mail, de maneira imediata, ao tomar conhecimento da violação de qualquer dos termos e condições de uso presente.


      3.	Programa de recompensas

      O NOITADA conta com um sistema de recompensas por meio do qual o aplicativo Noitada, a seu critério exclusivo, poderá recompensar os usuários com base na quantidade de "Pontos" recebidos ou na qualidade das respostas de um usuário. Você concorda em atribuir Pontos por merecimento, bem como, em evitar quaisquer tentativas de manipular o sistema de recompensas, (por exemplo, ao criar várias contas ou respostas artificiais). Você confirma que a decisão da Noitada a respeito de qualquer recompensa deverá ser definitiva e vinculativa.


      4.	Direitos que concedemos a você

      Concedemos a você uma licença limitada, não exclusiva e revogável para fazer uso do Serviço do aplicativo Noitada, e uma licença limitada, não exclusiva e revogável para fazer uso pessoal, não comercial e de entretenimento do Conteúdo (a "Licença"). Esta Licença deverá permanecer em vigor até e a menos que seja encerrada por você ou pelo NOITADA. Você promete e concorda que está usando o Conteúdo para seu uso pessoal, não comercial e de entretenimento, e que não redistribuirá ou transferirá o Serviço ou o Conteúdo.

      Os aplicativos de software e o Conteúdo da Noitada são licenciados, e não vendidos, para você, e o NOITADA e seus licenciadores detêm a propriedade de todas as cópias dos aplicativos de software e do Conteúdo da Noitada, mesmo após a instalação em seus computadores pessoais, celulares, tablets e/ou outros dispositivos relevantes ("Dispositivos").


      5.	Direitos que você concede a nós

      Se fornecer feedback, ideias ou sugestões para o NOITADA em relação ao aplicativo Noitada ou ao Conteúdo ("Feedback"), você confirma que o Feedback não é confidencial e autoriza o NOITADA a usar esse Feedback sem restrição e sem pagamento a você. O Feedback é considerado um tipo de Conteúdo do Usuário.


      6.	Alterações nos Acordos

      O NOITADA se reserva o direito de incluir, excluir ou alterar os conteúdos e funcionalidades do aplicativo, bem como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento, independentemente de aviso-prévio ao Usuário.

      Ocasionalmente, poderemos, a nosso critério, fazer alterações nos Acordos, cuja versão mais recente estará sempre disponível para consulta no próprio aplicativo. Quando fizermos alterações materiais nos Acordos, forneceremos a você um aviso em destaque conforme apropriado segundo as circunstâncias, por ex., exibindo um aviso em destaque dentro do Serviço ou enviando um e-mail a você. Em alguns casos, informaremos você previamente, e seu uso contínuo do Serviço depois que as alterações tiverem sido feitas constituirá na aceitação das alterações.

      Portanto, certifique-se de ler atentamente qualquer aviso do tipo. Se você não quiser continuar usando o Serviço segundo a nova versão dos Acordos, poderá encerrar os Acordos entrando em contato conosco por meio do formulário de contato de Atendimento ao Cliente.


      7.	Suporte ao Cliente

      Para obter informações sobre suporte ao cliente como perguntas relacionadas a contas ou a programas de recompensas por exemplo ("Perguntas de Suporte ao Cliente"), envie um chamado para nosso departamento de atendimento ao cliente, usando o formulário de contato de Atendimento no aplicativo Noitada ou o formulário de Atendimento ao Cliente em nosso site. Empreenderemos esforços razoáveis para responder a todas as Perguntas de Suporte ao Cliente em um período razoável, mas não fornecemos garantias de qualquer tipo de que as Perguntas de Suporte ao Cliente serão respondidas dentro de qualquer período específico e/ou de que poderemos responder satisfatoriamente a tais perguntas.


      8.	Vigência e término

      Os Acordos continuarão se aplicando a você até que sejam encerrados por você ou pelo NOITADA. No entanto, você confirma e concorda que a licença vitalícia concedida por você em relação ao Conteúdo do Usuário, incluindo Feedback, é irrevogável e, portanto, continuará após a expiração ou o término de qualquer um dos Acordos por qualquer motivo. O NOITADA poderá encerrar os Acordos ou suspender seu acesso ao aplicativo Noitada a qualquer momento, incluindo no caso do seu uso não autorizado real ou suspeito do Serviço e/ou Conteúdo, ou da não conformidade com os Acordos.

      Se você ou o NOITADA encerrar os Acordos, ou se o NOITADA suspender seu acesso ao aplicativo Noitada, você concordará que o NOITADA não deverá ter nenhuma responsabilidade com relação a você e que o NOITADA não reembolsará nenhum valor, na medida máxima permitida segundo a lei aplicável.

      Para saber como encerrar sua conta do aplicativo Noitada, entre em contato conosco por meio do formulário de contato de Atendimento no aplicativo Noitada ou o formulário de Atendimento ao Cliente em nosso site. Esta seção estará em vigor até a medida permitida pela lei aplicável. Você poderá encerrar os Acordos a qualquer momento.

      As seções dos Acordos que, seja explicitamente ou por sua natureza, devem permanecer em vigor mesmo após o encerramento dos Acordos, deverão continuar em vigor após o encerramento.


      9.	Proteção à marca

      Todas as marcas comerciais, marcas de serviço, nomes comerciais, logotipos, nomes de domínio do aplicativo Noitada e quaisquer outros recursos da marca do aplicativo Noitada ("Recursos da Marca do aplicativo Noitada"), tais como seus processos, bancos de dados, redes, conteúdo como código fonte, conteúdos de telas, arquivos e demais conteúdos relacionados ao aplicativo em questão são de propriedade exclusiva da Noitada ou de seus licenciadores. Os Acordos não concedem a você qualquer direito de usar qualquer Recurso da Marca do aplicativo Noitada, seja para uso comercial ou não comercial.

      Não podem ser copiadas, imitadas ou utilizadas, no todo ou em parte, sem a permissão prévia por escrito da Noitada, nada relacionado a mesma, exceto quando de acordo com eventual estipulação contratual permissiva. Além disso, todos os cabeçalhos, gráficos personalizados, ícones e scripts são marcas comerciais da Noitada, e não podem ser copiadas, imitadas ou utilizadas, no todo ou em parte, sem permissão prévia por escrito da Noitada.


      10.	Foro para dirimir controvérsias

      A competência para o conhecimento, processamento ou julgamento de qualquer feito judicial, administrativo ou extrajudicial será o foro da comarca de Salvador, na Bahia, Brasil, renunciando a parte contrária a qualquer outro, por mais privilegiado seja.

      O descumprimento das ofertas prestadas e obrigações assumidas por terceiros, ainda que através do aplicativo Noitada e dos seus sites correlacionados, não sujeitará os detentores de seus direitos autorais a responsabilidade civil ou à reparação de danos de qualquer natureza, sendo que estes não são proprietários dos estabelecimentos anunciantes ou possuem vínculo jurídico com os eventos anunciados, atuando como meros prestadores de serviços de publicidade aos anunciantes.



      Se você ainda possui alguma dúvida sobre a forma de utilização de nosso produto ou sobre o nosso termo de uso ou nossa política de privacidade e seus direitos e deveres perante o aplicativo Noitada, poderá entrar em contato a qualquer momento.

    </body>


  );
};



export default TermsNoitadaUseScreen;
