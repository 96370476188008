// Landings
import _playButton from '../assets/svgs/play-button.svg';



export const SVG_ITEMS = {
  playButton:                       _playButton,
};



const Svgs = {
  ...SVG_ITEMS,
};


  
export default Svgs;
