import React from 'react';

import FooterTerms from '../../../components/Footers/FooterTerms';

import NavbarLanding from '../../../components/Navbar/NavbarLanding';

import {
  Images,
} from '../../../shared/constants';

import {
  TermsPoliciesTitleView,
  TermsPoliciesTitleInfoView,
  TermsPoliciesHeaderTitle,
  TermsPoliciesBreadcrumbView,
  TermsPoliciesBackgroundView,
  TermsPoliciesBackgroundImage,
  TermsPoliciesDocContainer,
  TermsPoliciesDocMenu,
  TermsPoliciesDocItemMenu,
  TermsPoliciesDocChildrenView,
  TermsPoliciesDocIntroView,
  TermsPoliciesDocIntroHeader,
  TermsPoliciesDocIntroTitle,
  TermsPoliciesDocIntroDate,
  TermsPoliciesDocIntroInfo,
  TermsPoliciesDocIntroText,
  TermsPoliciesDocSectionView,
  TermsPoliciesDocSectionTitle,
  TermsPoliciesDocTextView,
  TermsPoliciesDocTextDesc,
  TermsPoliciesDocOptionsView,
  TermsPoliciesDocOptionsItem,
  TermsPoliciesDocOptionsIcon,
  TermsPoliciesDocOptionsText,
} from './styled';



export interface IProps {
  showToolbar?: boolean;
  title: string;
  appType: 'APP' | 'ORGANIZER';
  breadcrumb?: string;
  backgroundImage?: string;
  termsData?: any,
  children?: any;
}



const TermsPoliciesContainer: React.FC<IProps> = (props: IProps) => {
  const introTerm = props.termsData?.intro;
  const menuTerm = [
    ...[introTerm],
    ...props.termsData?.data,
  ];
  const dataListTerm = props.termsData?.data;


  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_initial',
    },
  ];



  function renderChildrenContent() {
    return (

      <div>

        <TermsPoliciesTitleView>

          <TermsPoliciesTitleInfoView>

            <TermsPoliciesHeaderTitle>
              {props.title || '-'}
            </TermsPoliciesHeaderTitle>


            {props.breadcrumb && (
              <TermsPoliciesBreadcrumbView>
                {props.breadcrumb}
              </TermsPoliciesBreadcrumbView>
            )}

          </TermsPoliciesTitleInfoView>



          <TermsPoliciesBackgroundView>
            <TermsPoliciesBackgroundImage
              src={props.backgroundImage}
            />
          </TermsPoliciesBackgroundView>

        </TermsPoliciesTitleView>



        <TermsPoliciesDocContainer>

          <TermsPoliciesDocMenu>

            {menuTerm?.map((itemTitle: any) => (
              <TermsPoliciesDocItemMenu
                href={`#${itemTitle?.href}`}>
                {itemTitle?.title}
              </TermsPoliciesDocItemMenu>
            ))}

          </TermsPoliciesDocMenu>



          <TermsPoliciesDocChildrenView>

            {introTerm && (
              <TermsPoliciesDocIntroView
                id={introTerm?.href}>

                <TermsPoliciesDocIntroHeader>

                  <TermsPoliciesDocIntroTitle>
                    {introTerm.title || '-'}
                  </TermsPoliciesDocIntroTitle>


                  <TermsPoliciesDocIntroDate>
                    Atualizado em: {introTerm.updateAt || '-'}
                  </TermsPoliciesDocIntroDate>

                </TermsPoliciesDocIntroHeader>


                {introTerm?.paragraphs && introTerm?.paragraphs?.length > 0 && introTerm?.paragraphs?.map((itemParag: any) => (
                  <TermsPoliciesDocIntroInfo>

                    <TermsPoliciesDocIntroText>
                      {itemParag?.text}
                    </TermsPoliciesDocIntroText>

                  </TermsPoliciesDocIntroInfo>
                ))}

              </TermsPoliciesDocIntroView>
            )}



            {dataListTerm?.map((itemTitle: any, index: number) => (
              <TermsPoliciesDocSectionView
                id={itemTitle?.href}
                key={index}>

                {itemTitle?.title && (
                  <TermsPoliciesDocSectionTitle>
                    {itemTitle?.title}
                  </TermsPoliciesDocSectionTitle>
                )}


                {itemTitle?.paragraphs && itemTitle?.paragraphs?.length > 0 && itemTitle?.paragraphs?.map((itemParag: any) => (
                  <TermsPoliciesDocTextView>

                    <TermsPoliciesDocTextDesc>
                      {itemParag?.text}
                    </TermsPoliciesDocTextDesc>



                    {itemParag?.submenu && itemParag?.submenu?.length > 0 && (
                      <TermsPoliciesDocOptionsView>

                        {itemParag?.submenu?.map((itemMenuSub: any) => (
                          <TermsPoliciesDocOptionsItem>

                            <TermsPoliciesDocOptionsIcon>
                              {' * '}
                            </TermsPoliciesDocOptionsIcon>



                            <TermsPoliciesDocOptionsText>
                              {itemMenuSub?.text}
                            </TermsPoliciesDocOptionsText>

                          </TermsPoliciesDocOptionsItem>
                        ))}

                      </TermsPoliciesDocOptionsView>
                    )}

                  </TermsPoliciesDocTextView>
                ))}

              </TermsPoliciesDocSectionView>
            ))}



            {props.children && props.children}

          </TermsPoliciesDocChildrenView>

        </TermsPoliciesDocContainer>

      </div>

    );
  }



  if (props.showToolbar) {
    return (

      <NavbarLanding
        type={'FIXED'}
        subtitle={'Termos e Políticas'}
        navItems={navItemsPage}>

        {renderChildrenContent()}


        <FooterTerms />

      </NavbarLanding>

    );
  }
  else {
    return renderChildrenContent();
  }
};



export default TermsPoliciesContainer;
