import React from 'react';

import {
  Images,
  Keys,
} from '../../../shared/constants';
import CardItems from '../../../components/Cards/Landings/CardItems';

import {
  AboutPresskitContainer,
  AboutPresskitTitlesView,
  AboutPresskitTitle,
  AboutPresskitSubtitle,
  AboutPresskitEmailButton,
  AboutPresskitEmailText,
  AboutPresskitSection,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionPressKit: React.FC<IProps> = (props) => {
  const emailToPress = 'press@noitada.net';



  function openDownloadLink(link: string) {
    const openWindow = window.open(link, '_blank');
    if (openWindow) {
      openWindow.focus();
    }
  }


  function rendercardPressKit(title: string, image: string, linkToPress: string) {
    return (

      <CardItems
        title={title}
        image={image}
        onPress={() => {
          openDownloadLink(linkToPress);
        }}
      />

    );
  }



  return (

    <AboutPresskitContainer
      id={props.id}>

      <AboutPresskitTitlesView>
        <AboutPresskitTitle>
          {'Press'.toUpperCase()}
        </AboutPresskitTitle>

        <AboutPresskitSubtitle>
          {'Aqui está tudo que precisa para usar a marca de acordo com os nossos padrões. Para obter mais informações sobre a Noitada, solicitação de pauta ou outras demandas, entre em contato por email.'}
        </AboutPresskitSubtitle>

        <AboutPresskitEmailButton
          href={`mailto:${emailToPress}`}>
          <AboutPresskitEmailText>
            {emailToPress}
          </AboutPresskitEmailText>
        </AboutPresskitEmailButton>
      </AboutPresskitTitlesView>


      <AboutPresskitSection>

        {rendercardPressKit(
          'Logo',
          Images.kitLogo,
          Keys.logosDownloadLink,
        )}


        {rendercardPressKit(
          'Icones',
          Images.kitIcon,
          Keys.iconDownloadLink,
        )}


        {rendercardPressKit(
          'Telas',
          Images.kitUI,
          Keys.screensDownloadLink,
        )}


        {rendercardPressKit(
          'Fotos',
          Images.kitPhotos,
          Keys.imagesDownloadLink,
        )}

      </AboutPresskitSection>

    </AboutPresskitContainer>

  );
};



export default SectionPressKit;
