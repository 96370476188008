import {
  EProTypes,
} from '../shared/enums';



export function ReturnProLevelLabel(proLevel: string): string {
  if (!proLevel) {
    return 'Sem pro';
  }
  switch (proLevel.toUpperCase()) {
    case EProTypes.NO_PRO:
      return 'Gratuito';

    case EProTypes.LIGHT:
      return 'Light';

    case EProTypes.MEDIUM:
      return 'Medium';

    case EProTypes.SUPER:
      return 'Super';

    case EProTypes.SUPER_FREEMIUM:
      return 'Super (Amostra)';

    case EProTypes.PERSONALITY:
      return 'Personality';

    default:
      return proLevel;
  }
}
