import styled from 'styled-components';

import {
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';

import {
  TitleContents,
  DescriptionContents,
  PaddingContainer,
} from '../../../../styles/styled.layout';



export const ToolsProContainer = styled(PaddingContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${Sizes.desktopPaddingVertical};
`;

export const ToolsProTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


export const ToolsProTitle = styled(TitleContents)`
  text-align: center;
`;

export const ToolsProSubtitle = styled(DescriptionContents)`
  text-align: center;
`;



export const ToolsProSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: ${Sizes.marginFromTitles};
  gap: 30px;

  @media ${PlatformDevices.maxMobileL} {
    flex-direction: column;
    align-items: center;
  }

  @media ${PlatformDevices.minMobileL} {
    flex-direction: row;
    justify-content: center;
  }
`;
