import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  openSite,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  Images,
} from '../../../shared/constants';

import Vars from '../../../shared/environments/variables';

import ContentAbout from '../../Containers/ContentAbout';



export interface IProps {
  id?: string;
}



const ContentPRO: React.FC<IProps> = (props) => {
  const history = useHistory();


  function openAccoutOrganizerRegister() {
    openSite(Vars().contaOrganizerRegister);
  }


  function openProLanding() {
    history.push(NameRoutes.ProLandingScreen);
  }



  return (

    <ContentAbout
      id={props.id}
      image={Images.logoPROIconColor}
      title={'Noitada PRO!'}
      subtitle={'Para pequenos e grandes parceiros, a Noitada oferece serviços sob medida e diversas soluções mais robustas'}
      backgroundImage={Images.backgroundPRO}

      buttonOneText={'Conhecer o PRO'}
      buttonOnePress={() => {
        openProLanding();
      }}

      buttonTwoText={'Criar conta grátis'}
      buttonTwoPress={() => {
        openAccoutOrganizerRegister();
      }}
    />

  );
};



export default ContentPRO;
