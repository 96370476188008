import styled from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



export const LandingAboutContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
`;


export const LandingOrganizerViews = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
`;

export const LandingOrganizerLogoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const LandingOrganizerImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 5px;
  object-fit: contain;

  @media (max-width: 640px) {
    margin-top: 100px;
  }
`;

export const LandingOrganizerText = styled.p`
  color: ${Colors.white};
  font-weight: 400;
  font-size: 22px;
  
  @media (max-width: 640px) {
    margin-top: 100px;
  }
`;


export const LandingOrganizerTitle = styled.h1`
  color: ${Colors.white};
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 53px;
  
  @media(max-width: 635px) {
    margin-top: 8px;
    font-size: 50px;
    text-align: center;
  }

  @media(max-width: 600px) {
    font-size: 40px;
    text-align: center;
  }

  @media(max-width: 410px) {
    font-size: 35px;
    text-align: center;
  }
`;

export const LandingOrganizerDescription = styled.h3`
  color: #9496A0;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  
  @media(max-width: 635px) {
    text-align: center;
  }

  @media(max-width: 600px) {
    text-align: center;
  }

  @media(max-width: 410px) {
    text-align: center;
  }
`;


export const LandingOrganizerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;
