import styled,
{
  css,
} from 'styled-components';

import {
  LinearGradient,
  Scrolls,
} from '../../../../components/Composh/web';

import {
  GetPlatform,
} from '../../../../config/plataform.config';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  EProTypes,
} from '../../../../shared/enums';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  colHoverNthChild?: any;
}



export const OffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${Colors.black};
  padding-bottom: 10px;
`;

export const OffersImageBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProOffersOverlay = styled(LinearGradient)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`;



export const OffersContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;

  @media ${PlatformDevices.maxLaptopM} {
    align-items: center;
  }

  @media ${PlatformDevices.maxLaptopM} {
    padding-top: ${Sizes.mobilePaddingVertical};
    padding-bottom: ${Sizes.mobilePaddingVertical};
  }

  @media ${PlatformDevices.minLaptopM} {
    padding-top: ${Sizes.desktopPaddingVertical};
    padding-left: ${Sizes.desktopPaddingHorizontal};
    padding-right: ${Sizes.desktopPaddingHorizontal};
    padding-bottom: ${Sizes.desktopPaddingVertical};
  }
`;


export const ProPlansSectionScrollText = styled.p`
  margin-bottom: 30px;
  padding-left: ${Sizes.mobilePaddingHorizontal};
  padding-right: ${Sizes.mobilePaddingHorizontal};
  color: ${Colors.grayLight};
  font-size: 14px;
  font-style: italic;
  text-align: center;

  @media ${PlatformDevices.minLaptop} {
    display: none;
  }
`;



export const ProPlansScrollStyled = styled(Scrolls.Horizontal)`
  width: 100%;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    height: 17px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.black};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.black};
    border-style: solid;
    border-top-width: 9px;
    border-left-width: 15px;
    border-right-width: 15px;
    border-bottom-width: 1px;
  }
`;

export const ProPlansWrapperView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 940px;

  @media ${PlatformDevices.minLaptopM} {
    align-items: flex-end;
  }
`;


export const TableProHover = css`
  th:nth-child(${(props: IProps) => props.colHoverNthChild}),
  td:nth-child(${(props: IProps) => props.colHoverNthChild}),
  tbody:hover {
    #${EProTypes.NO_PRO},
    #${EProTypes.LIGHT},
    #${EProTypes.MEDIUM},
    #${EProTypes.SUPER} {
      background-color: ${Colors.primaryDark};
    }
  }
`;

export const OffersTableContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const OffersTable = styled.table`
  width: 840px;
  font-size: 25px;
  border-collapse: collapse;
  transition: all .5s;

  #${EProTypes.NO_PRO} {
    transition: all .2s;
    background-color: ${Colors.buttonDisabledOpacity};
  }

  #${EProTypes.LIGHT},
  #${EProTypes.MEDIUM},
  #${EProTypes.SUPER} {
    transition: all .2s;
    background-color: ${Colors.buttonOpacity};
  }

  
  ${GetPlatform() === 'WEB' && TableProHover}
`;


export const HeightRow = css`
  height: 60px;
`;

export const OffersTitlesTr = styled.tr`
  ${HeightRow};
  text-align: left;
`;


export const OffersSingleTh = styled.th`
  padding-left: 5px;
  padding-right: 5px;
`;

export const OffersSingleTitle = styled.h3`
  color: ${Colors.white};
  font-size: 17px;
  font-weight: 200;
`;

export const OffersSingleSubTitle = styled.p`
  margin-top: 5px;
  color: ${Colors.textApp};
  font-size: 14px;
  font-weight: 200;
`;

export const OffersSingleSectionTitle = styled(OffersSingleTh)`
  color: ${Colors.white};
  font-size: 24px;
  font-weight: bold;
`;


export const WidthTable = css`
  width: 140px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
`;

export const TextStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 200;
  padding-left: 10px;
  padding-right: 10px;
`;

export const OffersTypeTitle = styled.th`
  ${WidthTable};
`;

export const OffersItemViewTitle = styled.p`
  ${TextStyle};
  ${HeightRow};
  color: ${Colors.white};
  font-size: 20px;
`;


export const OffersItemText = styled.td`
  ${WidthTable};
`;

export const OffersItemViewText = styled.p`
  ${TextStyle};
  ${HeightRow};
  color: ${Colors.textDescription};
  font-size: 14px;
`;
