import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  PaddingContainer,
  TitleContents,
  SubTitleContents,
  DescriptionContents,
} from '../../../styles/styled.layout';



export const BenefitsRowContainer = styled(PaddingContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(to right, ${Colors.linearTopApp}, ${Colors.linearBottomApp});
`;

export const BenefitsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;

  @media ${PlatformDevices.maxLaptopL} {
   width: 100%;
   align-items: center;
  }
`;

export const BenefitsInfoInnerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BenefitsInfoTitle = styled(TitleContents)`
  @media ${PlatformDevices.maxLaptopL} {
   text-align: center;
  }
`;

export const BenefitsInfoSubTitle = styled(DescriptionContents)`
  @media ${PlatformDevices.maxLaptopL} {
   text-align: center;
  }
`;



export const ImageLeftView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 480px;

  @media ${PlatformDevices.maxLaptopM} {
   display: none;
  }
`;


export const ImageScreenApp = styled.img`
  height: 470px;
  margin-bottom: 0px;
  object-fit: contain;
`;



export const BenefitsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 45px;
  gap: 30px 0;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
  }
`;

export const BenefitsItemsContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

export const BenefitsItemsImageContent = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 25px;

  @media ${PlatformDevices.maxTablet} {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const BenefitsItemsInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 135px;
  height: 140px;
  margin-right: 35px;

  @media ${PlatformDevices.maxTablet} {
    align-items: center;
    margin-right: 0;
    width: 100%;
  }
`;

export const BenefitsItemsInfoTitle = styled(SubTitleContents)`
  @media ${PlatformDevices.maxTablet} {
    text-align: center;
  }
`;

export const BenefitsItemsInfoText = styled.p`
  margin-top: 6px;
  color: ${Colors.textSubDescription};
  font-size: 16px;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
    text-align: center;
  }
`;

export const BenefitsButtonContent = styled.div`
  display: flex;
  margin-top: 10px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    margin-top: 35px;
    gap: 20px; 
  }

  @media ${PlatformDevices.minTablet} {
    flex-direction: row;
    width: 100%;
    gap: 35px;
  }

  @media ${PlatformDevices.maxLaptopM} {
    align-items: center;
    justify-content: center;
  }

  @media ${PlatformDevices.minLaptopM} {
    flex-direction: row;
    align-items: center;
    gap: 40px;
  }
`;
