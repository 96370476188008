import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  openSite,
} from '../../config/linking.config';

import NameRoutes from '../../navigation/names';

import {
  Colors,
  Images,
  Keys,
  Sizes,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import {
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialTwitter,
  IconSocialYoutube,
  IconEmail,
  IconSocialLinkedIn,
  IconSocialThreads,
  IconSocialTikTok,
} from '../Icons';

import {
  LinkFooter,
  FooterContainer,
  TitleTree,
  FooterSitemap,
  FooterColNormal,
  FooterColSocial,
  FooterLogo,
  FooterTitle,
  FooterListView,
  FooterItemsText,
  FooterSocialTitle,
  ImageHeartStyle,
  FooterItemsSocialList,
  ButtonFacebook,
  ButtonInstagram,
  ButtonTwitter,
  ButtonYoutube,
  ButtonThreads,
  ButtonTikTok,
  ButtonLinkedIn,
  ButtonEmail,
  FooterTv,
  ButtonTv,
  LogoTvImage,
} from './styled';



export interface IProps {
  id?: string;
}



const TreeScreens: React.FC<IProps> = (props: any) => {
  const history = useHistory();



  return (

    <FooterContainer
      id={props.id}>

      <TitleTree>
        {'Estamos sempre aqui!'.toUpperCase()}
      </TitleTree>



      <FooterSitemap>

        <FooterColNormal>
          <FooterLogo
            src={Images.logoNoitadaFullWhite}
            alt={'Logo'}
          />

          <FooterListView>
            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.LandingNoitadaScreen);
              }}>
              <FooterItemsText>
                Noitada App
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.OrganizerAboutLandingScreen);
              }}>
              <FooterItemsText>
                Noitada Organizer
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.ProLandingScreen);
              }}>
              <FooterItemsText>
                Noitada PRO
              </FooterItemsText>
            </LinkFooter>


            {/*
            <LinkFooter
              target={'_blank'}
              onClick={()=>{
                history.push(NameRoutes.BlogScreen);
              }>
              <FooterItemsText>
                Blog
              </FooterItemsText>
            </LinkFooter>
            */}


            {/*
            <LinkFooter
              onClick={()=>{
                history.push(NameRoutes.AboutScreen);
              }>
              <FooterItemsText>
                Sobre
              </FooterItemsText>
            </LinkFooter>
            */}


            {/*
            <LinkFooter
              onClick={()=>{
                history.push(NameRoutes.ABetterNightScreen);
              }>
              <FooterItemsText>
                Uma noite melhor
              </FooterItemsText>
            </LinkFooter>
            */}

          </FooterListView>


          <FooterListView>
            <LinkFooter
              target={'_blank'}
              onClick={() => {
                history.push(NameRoutes.TermsPoliciesScreen);
              }}>
              <FooterItemsText>
                Termos e Políticas
              </FooterItemsText>
            </LinkFooter>
          </FooterListView>

        </FooterColNormal>



        <FooterColNormal>
          <FooterTitle>
            Para Empresas
          </FooterTitle>


          <FooterListView>
            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.OrganizerDetailsLandingScreen);
              }}>
              <FooterItemsText>
                Organizer da Noitada
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              onClick={() => {
                history.push(`${NameRoutes.OrganizerDetailsLandingScreen}#steps`);
              }}>
              <FooterItemsText>
                Ferramentas
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              onClick={() => {
                history.push(`${NameRoutes.OrganizerDetailsLandingScreen}#how-works`);
              }}>
              <FooterItemsText>
                Como funciona
              </FooterItemsText>
            </LinkFooter>


            {/*
            <LinkFooter
              onClick={()=>{
                history.push(`${NameRoutes.BlogScreen}#sucess-case`);
              }>
              <FooterItemsText>
                Cases de sucesso
              </FooterItemsText>
            </LinkFooter>
            */}


            {/*
            <LinkFooter
              onClick={()=>{
                history.push(`${NameRoutes.ContactScreen}?consult=true`);
              }>
              <FooterItemsText>
                Fale com um Consultor
              </FooterItemsText>
            </LinkFooter>
            */}

          </FooterListView>



          {/*
          <FooterList>
            <LinkFooter
              target={'_blank'}
              onClick={()=>{
                history.push(NameRoutes.DevelopersScreen);
              }>
              <FooterItemsText>
                Para Desenvolvedores
              </FooterItemsText>
            </LinkFooter>
          </FooterList>
          */}

        </FooterColNormal>



        <FooterColNormal>
          <FooterTitle>
            Ajuda
          </FooterTitle>


          <FooterListView>

            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.TermsPoliciesScreen);
              }}>
              <FooterItemsText>
                Termos e Políticas
              </FooterItemsText>
            </LinkFooter>


            {/*
            <LinkFooter
              target={'_blank'}
              onClick={()=>{
                history.push(NameRoutes.HelpCenterScreen);
              }>
              <FooterItemsText>
                Central de ajuda
              </FooterItemsText>
            </LinkFooter>
            */}


            {/*
            <LinkFooter
              target={'_blank'}
              onClick={() => {
                history.push(NameRoutes.HelpUserScreen);
              }}>
              <FooterItemsText>
                Usuários
              </FooterItemsText>
            </LinkFooter>
          */}


            {/*
            <LinkFooter
              target={'_blank'}
              onClick={() => {
                history.push(NameRoutes.HelpPartnerScreen);
              }}>
              <FooterItemsText>
                Parceiros
              </FooterItemsText>
            </LinkFooter>
          */}


            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.ContactScreen);
              }}>
              <FooterItemsText>
                Contato
              </FooterItemsText>
            </LinkFooter>

          </FooterListView>



          {/*
          <FooterListView>
            <LinkFooter
              onClick={() => {
                history.push(NameRoutes.PressScreen);
              }}>
              <FooterItemsText>
                Imprensa
              </FooterItemsText>
            </LinkFooter>
          </FooterListView>
        */}



          {/*
          <FooterListView>
            <LinkFooter
              target={'_blank'}
              onClick={() => {
                history.push({ pathname: Keys.persasSiteLink });
              }}>
              <FooterItemsText>
                Persas
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              target={'_blank'}
              onClick={() => {
                history.push(NameRoutes.JobsScreen);
              }}>
              <FooterItemsText>
                Trabalhe conosco
              </FooterItemsText>
            </LinkFooter>
          </FooterListView>
        */}

        </FooterColNormal>



        <FooterColNormal>

          <FooterTitle>
            Acesse Agora
          </FooterTitle>



          <FooterListView>

            <LinkFooter
              target={'_blank'}
              onClick={() => {
                openSite(Vars().contaGlobalApp);
              }}>
              <FooterItemsText>
                Conta Global
              </FooterItemsText>
            </LinkFooter>

          </FooterListView>



          <FooterListView>

            <LinkFooter
              target={'_blank'}
              onClick={() => {
                openSite(Vars().appNoitadaSite);
              }}>
              <FooterItemsText>
                Noitada App
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              target={'_blank'}
              onClick={() => {
                openSite(Vars().organizerNoitadaSite);
              }}>
              <FooterItemsText>
                Noitada Organizer
              </FooterItemsText>
            </LinkFooter>


            {/*
            <LinkFooter
              target={'_blank'}
              onClick={()=>{
                history.push({ pathname: Vars().storeNoitadaSite });
              }>
              <FooterItemsText>
                Loja da Noitada
              </FooterItemsText>
            </LinkFooter>
            */}


            {/*
            <LinkFooter
              onClick={()=>{
                history.push(NameRoutes.GetAppsLandingScreen);
              }>
              <FooterItemsText>
                Baixe os aplicativos
              </FooterItemsText>
            </LinkFooter>
            */}

          </FooterListView>



          {/*
          <FooterList>
            <LinkFooter
              onClick={()=>{
                history.push(NameRoutes.FestivalHomeScree);n}
              target={'_blank'}>
              <FooterItemsText>
                Noitada Music Festival
              </FooterItemsText>
            </LinkFooter>
          </FooterList>
          */}


          {/*
          <FooterList>
            <LinkFooter
              onClick={()=>{
                history.push(NameRoutes.CountriesScreen);
              }>
              <FooterItemsText>
                Noitada no Mundo
              </FooterItemsText>
            </LinkFooter>


            <LinkFooter
              target={'_blank'}
              onClick={()=>{
                history.push({ pathname: NameRoutes.SocialLinksScreen });
              }>
              <FooterItemsText>
                Noitada LinkBio
              </FooterItemsText>
            </LinkFooter>
          </FooterList>
          */}

        </FooterColNormal>



        <FooterColSocial>

          <FooterSocialTitle>
            Nós <ImageHeartStyle src={Images.iconLove} alt={'Love'} /> novos amigos!
          </FooterSocialTitle>



          <FooterItemsSocialList>

            <ButtonFacebook
              target={'_blank'}
              href={Keys.facebookLink}>
              <IconSocialFacebook
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonFacebook>


            <ButtonInstagram
              target={'_blank'}
              href={Keys.instagramLink}>
              <IconSocialInstagram
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonInstagram>


            <ButtonTwitter
              target={'_blank'}
              href={Keys.twitterLink}>
              <IconSocialTwitter
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonTwitter>


            <ButtonLinkedIn
              target={'_blank'}
              href={Keys.linkedInLink}>
              <IconSocialLinkedIn
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonLinkedIn>


            <ButtonYoutube
              target={'_blank'}
              href={Keys.youtubeLink}>
              <IconSocialYoutube
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonYoutube>


            <ButtonThreads
              target={'_blank'}
              href={Keys.threadsLink}>
              <IconSocialThreads
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonThreads>


            <ButtonTikTok
              target={'_blank'}
              href={Keys.tiktokLink}>
              <IconSocialTikTok
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonTikTok>


            <ButtonEmail
              href={Keys.noitadaEmailContact}>
              <IconEmail
                color={Colors.white}
                size={Sizes.iconSocialTreePages}
              />
            </ButtonEmail>

          </FooterItemsSocialList>



          <FooterTv>

            <FooterSocialTitle>
              Outros canais oficiais
            </FooterSocialTitle>


            <FooterItemsSocialList>

              <ButtonTv
                target={'_blank'}
                href={Keys.youtubeLink}>
                <LogoTvImage
                  src={Images.logoTvIconWhite}
                  alt={'Tv Logo'}
                />
              </ButtonTv>

            </FooterItemsSocialList>
          </FooterTv>

        </FooterColSocial>

      </FooterSitemap>

    </FooterContainer>

  );
};



export default TreeScreens;
