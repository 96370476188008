import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



export const EConContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 700px;
  background: linear-gradient(to right, ${Colors.linearTopApp}, ${Colors.linearBottomApp});
`;

export const ElementorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 777px;
  aspect-ratio: auto 2560 / 1709;
  border-radius: 24px;
`;

export const EWidgetImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const EWidgetOverlay = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardOverlay};
`;

export const EConPlayButton = styled.div`
  width: 150px;
  height: 150px;
  transition: all .3s;

  ${EWidgetOverlay}:hover & {
    transform: scale(1.1);
  }
`;

export const EConPlayImageutton = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;



export const EConInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100px;
  left: 100px;
  width: 600px;
  pointer-events: none;
`;

export const EConInfoSubTitle = styled.h4`
  pointer-events: none;
  line-height: 1.5;
  color: ${Colors.white};
  font-size: 32px;
  font-weight: 700;
`;

export const EConInfoTitle = styled.h3`
  pointer-events: none;
  color: ${Colors.white};
  font-size: 56px;
  font-weight: 700;
  line-height: 68px;
`;
