import styled from 'styled-components';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  SubTitleContents,
  TitleLandingHeader,
} from '../../../styles/styled.layout';



export const LandingAboutContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;

  @media ${PlatformDevices.maxLaptop} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const LandingOrganizerLogoView = styled.div`
  display: flex;
  flex-direction: column;

  @media ${PlatformDevices.maxLaptop} {
    margin-bottom: 10px;
  }

  @media ${PlatformDevices.maxTablet} {
    width: 140px;
    height: 140px;
  }

  @media ${PlatformDevices.minTablet} {
    width: 240px;
    height: 240px;
  }

  @media ${PlatformDevices.minLaptop} {
    width: 340px;
    height: 340px;
  }

  @media ${PlatformDevices.minDesktop} {
    width: 440px;
    height: 440px;
  }
`;

export const LandingOrganizerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;


export const LandingOrganizerViews = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${PlatformDevices.maxLaptop} {
    width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minLaptopM} {
    width: 550px;
    max-width: 550px;
  }

  @media ${PlatformDevices.minLaptop} {
    width: 750px;
    max-width: 750px;
  }
`;


export const LandingOrganizerTitle = styled(TitleLandingHeader)`
  margin-bottom: 12px;
`;

export const LandingOrganizerDescription = styled(SubTitleContents)`
`;


export const LandingOrganizerButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 27px;
`;
