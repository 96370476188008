import styled from 'styled-components';



const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
`;



export default DialogContent;
