import React from 'react';
import ReactDOM from 'react-dom';

// import {
//   getAnalytics,
// } from 'firebase/analytics';
import {
  initializeApp,
} from 'firebase/app';

import App from './App';

import AppEnvironments from './shared/environments/application';



initializeApp(AppEnvironments().firebaseWebConfig);
// getAnalytics(app);



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
