import styled from 'styled-components';

import ButtonAccess from '../../../../components/Buttons/ButtonChoose';

import {
  Scrolls,
} from '../../../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../../../shared/constants';

import {
  PlatformDevices,
} from '../../../../shared/utils/platforms.utils';



export interface IProps {
  color?: string;
  backgroundColor?: string;
}



export const ProPlansSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  background-color: ${Colors.appBackground};
`;

export const ProPlansSectionViewContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 35px;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: 70px;
  background-color: ${Colors.primaryDark};

  @media ${PlatformDevices.maxTabletL} {
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }
`;

export const ProPlansSectionTitleView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
   
  @media ${PlatformDevices.maxTabletL} {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @media ${PlatformDevices.minTabletL} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ProPlansSectionScrollText = styled.p`
  margin-top: 20px;
  color: ${Colors.grayLight};
  font-size: 14px;
  font-style: italic;
  text-align: center;

  @media ${PlatformDevices.minLaptopL} {
    display: none;
  }
`;


export const ProPlansHeroTitle = styled.p`
  color: ${Colors.white};
  font-weight: 700;

  @media ${PlatformDevices.maxTabletL} {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }

  @media ${PlatformDevices.minTabletL} {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 48px;
    line-height: 58px;
    text-align: left;
  }
`;

export const ProPlansHeroDescription = styled.p`
  color: ${Colors.white};
  font-weight: 500;

  @media ${PlatformDevices.maxTabletM} {
    margin-top: 15px;
  }

  @media ${PlatformDevices.maxTabletL} {
    font-size: 18px;
    text-align: center;
  }

  @media ${PlatformDevices.minTabletL} {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 21px;
    text-align: right;
  }
`;



// Plan Styles

export const ProPlansGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  z-index: 10;
`;

export const ProPlansScrollStyled = styled(Scrolls.Horizontal)`
  width: 100%;
  padding-bottom: 10px;

  ::-webkit-scrollbar {
    height: 17px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.appBackground};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.appBackground};
    border-style: solid;
    border-top-width: 9px;
    border-left-width: 15px;
    border-right-width: 15px;
    border-bottom-width: 1px;
  }
`;

export const ProPlansWrapperView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-width: 1300px;
`;


export const ProPlansGridCardItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 25%;
  min-width: 270px;
  max-width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 10px;
  background-color: ${Colors.toolbarBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const ProPlanMoreSaleView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.attention};
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const ProPlanMoreSaleIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const ProPlanMoreSaleText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 600;
`;



export const ProPlansGridCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  margin-bottom: 15px;
  border-top-left-radius: ${Sizes.cardRadius}px;
  border-top-right-radius: ${Sizes.cardRadius}px;
`;

export const ProPlanTextName = styled.p`
  color: ${Colors.white};
  font-size: 24px;
  font-weight: 700;
`;



export const ProPlansValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const ProPlanValuesAnualTitleView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const ProPlanValuesMoneySymbol = styled.sup`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 700;
  font-family: Manrope, sans-serif;
  line-height: 16px;
`;

export const ProPlanValuesPriceText = styled.p`
  margin-top: -5px;
  padding-left: 3px;
  padding-right: 3px;
  color: ${(props: IProps) => props.color || Colors.accent};
  font-size: 44px;
  font-weight: 700;
  font-family: Manrope, sans-serif;
  line-height: 44px;
`;



export const ProMakePlanOnPressButton = styled(ButtonAccess)`
  margin-top: 20px;
  width: 100%;
`;



export const ProPlanModulesView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProPlanModulesContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const ProPlanModulesTitle = styled.div`
  margin-bottom: 7px;
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 15px;
  font-weight: 700;
`;

export const ProModuleNameView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 7px;
  margin-bottom: 7px;
`;

export const ProModuleNameIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 21px;
  margin-right: 10px;
`;

export const ProModuleNameText = styled.div`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 14px;
  font-weight: 500;
`;
