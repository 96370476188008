import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  PaddingContainer,
  TitleContents,
  DescriptionContents,
} from '../../../styles/styled.layout';



export const AboutContent = styled(PaddingContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @media ${PlatformDevices.maxMobileL} {
    min-height: 400px;
  }

  @media ${PlatformDevices.minMobileL} {
    min-height: 380px;
  }

  @media ${PlatformDevices.minTablet} {
    min-height: 350px;
  }
`;


export const ContentOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  z-index: 3;
`;

export const ImageAbout = styled.img`
  width: 170px;
  height: 170px;
  margin-bottom: 1rem;
`;

export const AboutContentTitle = styled(TitleContents)`
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const AboutContentDescription = styled(DescriptionContents)`
  text-align: center;
`;


export const AboutButtonViews = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  @media ${PlatformDevices.minTablet} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 35px;
  }

  @media ${PlatformDevices.minTabletL} {
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
  }
`;



export const OverlayView = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${Colors.alertOverlay};
  top: 0;
  left: 0;
  z-index: 2;
`;
