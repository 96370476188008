import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
  Images,
} from '../../shared/constants';

import ButtonAccess from '../../components/Buttons/ButtonChoose';
import HelpHeader from '../../components/Containers/HelpHeader';
import FooterTerms from '../../components/Footers/FooterTerms';

import {
  IconProfile,
  IconTermCookies,
  IconTermData,
  IconTermLicences,
  IconTermPrivacity,
  IconTermUse,
} from '../../components/Icons';

import NavbarLanding from '../../components/Navbar/NavbarLanding';

import NameRoutes from '../../navigation/names';

import {
  TermsContentSegments,
  ContentColBlock,
  TitleItems,
  WrapperTermsList,
  ListBlockArticle,
  ListBlockIcon,
  TextButtonArticle,
  FaqCategoryContent,
  SectionTitleFaq,
  BlackBorderButton,
} from './styled';



const TermsPoliciesScreen: React.FC = () => {
  const history = useHistory();


  const navItemsPage = [
    // {
    //   navTitle: 'Central de ajuda',
    //   navHref: NameRoutes.HelpCenterScreen,
    // },
    {
      navTitle: 'Contato',
      navHref: NameRoutes.ContactScreen,
    },
  ];



  function renderOptionTerm(icon: any, title: any, onPress: any) {
    return (

      <ListBlockArticle
        href={onPress}>

        <ListBlockIcon>
          {icon}
        </ListBlockIcon>


        <TextButtonArticle>
          {title}
        </TextButtonArticle>

      </ListBlockArticle>

    );
  }



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <HelpHeader
        title={'Termos e Políticas'}
        description={'Agrupamos aqui toda a documentação essencial para utilização da plataforma da Noitada e suas funcionalidades. Boa leitura!'}
        backgroundImage={Images.backgroundIndex2}
      />



      <TermsContentSegments>

        <ContentColBlock>

          <TitleItems>
            Noitada App
          </TitleItems>


          <WrapperTermsList>

            {renderOptionTerm(
              <IconTermLicences
                color={Colors.white}
                size={24}
              />,
              'Licenças',
              NameRoutes.TermsNoitadaLicenseScreen,
            )}


            {renderOptionTerm(
              <IconTermUse
                color={Colors.white}
                size={24}
              />,
              'Termos de Uso',
              NameRoutes.TermsNoitadaUseScreen,
            )}


            {renderOptionTerm(
              <IconTermPrivacity
                color={Colors.white}
                size={24}
              />,
              'Política de Privacidade',
              NameRoutes.TermsNoitadaPrivacityScreen,
            )}


            {renderOptionTerm(
              <IconTermCookies
                color={Colors.white}
                size={24}
              />,
              'Política de Cookies',
              NameRoutes.TermsNoitadaCookiesScreen,
            )}

          </WrapperTermsList>

        </ContentColBlock>



        <ContentColBlock>

          <TitleItems>
            Noitada Organizer
          </TitleItems>


          <WrapperTermsList>

            {renderOptionTerm(
              <IconTermLicences
                color={Colors.white}
                size={24}
              />,
              'Licenças',
              NameRoutes.TermsOrganizerLicenseScreen,
            )}


            {renderOptionTerm(
              <IconTermUse
                color={Colors.white}
                size={24}
              />,
              'Termos de Uso',
              NameRoutes.TermsOrganizerUseScreen,
            )}


            {renderOptionTerm(
              <IconTermPrivacity
                color={Colors.white}
                size={24}
              />,
              'Política de Privacidade',
              NameRoutes.TermsOrganizerPrivacityScreen,
            )}


            {renderOptionTerm(
              <IconTermCookies
                color={Colors.white}
                size={24}
              />,
              'Política de Cookies',
              NameRoutes.TermsOrganizerCookiesScreen,
            )}



            {renderOptionTerm(
              <IconTermData
                color={Colors.white}
                size={24}
              />,
              'Política de Processamento de Dados',
              NameRoutes.TermsOrganizerDataScreen,
            )}

          </WrapperTermsList>

        </ContentColBlock>



        <ContentColBlock>

          <TitleItems>
            Terceiros
          </TitleItems>


          <WrapperTermsList>

            {renderOptionTerm(
              <IconProfile
                color={Colors.white}
                size={24}
              />,
              'Aviso de Privacidade para Pessoas Candidatas',
              NameRoutes.TermsOthersJobScreen,
            )}

          </WrapperTermsList>

        </ContentColBlock>

      </TermsContentSegments>



      {/*
      <FaqCategoryContent>
        <SectionTitleFaq>
          Tem dúvidas sobre alguma funcionalidade da Noitada?
        </SectionTitleFaq>

        <BlackBorderButton>
          <ButtonAccess
            scale={20}
            text={'Acesse a Central de ajuda'}
            onPress={() => {
              history.push(NameRoutes.HelpCenterScreen);
            }}
          />
        </BlackBorderButton>
      </FaqCategoryContent>
      */}



      <FooterTerms />

    </NavbarLanding>

  );
};



export default TermsPoliciesScreen;

