import React from 'react';

import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import AboutScreen from '../../screens/About';
import BlogScreen from '../../screens/Blog';
import CoinsScreen from '../../screens/Coins';
import ContactScreen from '../../screens/Contact';
import CountriesScreen from '../../screens/Countries';
import DevelopersScreen from '../../screens/Developers';
import Error403Screen from '../../screens/Errors/403';
import Error404Screen from '../../screens/Errors/404';
import Error500Screen from '../../screens/Errors/500';
import FestivalHomeScreen from '../../screens/Festival/FestivalHome';
import FestivalLineUpScreen from '../../screens/Festival/FestivalLineUp';
import GetAppsLandingScreen from '../../screens/GetApps';
import HelpCenterScreen from '../../screens/Help/HelpCenter';
import HelpPartnerScreen from '../../screens/Help/HelpPartner';
import HelpUserScreen from '../../screens/Help/HelpUser';
import JobsScreen from '../../screens/Jobs';
import LandingNoitadaScreen from '../../screens/Landings/Noitada';
import OrganizerAboutLandingScreen from '../../screens/Landings/Organizer/OrganizerAbout';
import OrganizerDetailsLandingScreen from '../../screens/Landings/Organizer/OrganizerDetails';
import ProLandingScreen from '../../screens/Landings/PRO';
import PressScreen from '../../screens/Press';
import ABetterNightScreen from '../../screens/SocialActions';
import TermsPoliciesScreen from '../../screens/TermsPolicies';
import TermsNoitadaCookiesScreen from '../../screens/TermsPolicies/Noitada/TermsCookies';
import TermsNoitadaLicenseScreen from '../../screens/TermsPolicies/Noitada/TermsLicense';
import TermsNoitadaPrivacityScreen from '../../screens/TermsPolicies/Noitada/TermsPrivacity';
import TermsNoitadaUseScreen from '../../screens/TermsPolicies/Noitada/TermsUse';
import TermsOrganizerCookiesScreen from '../../screens/TermsPolicies/Organizer/TermsCookies';
import TermsOrganizerDataScreen from '../../screens/TermsPolicies/Organizer/TermsData';
import TermsOrganizerLicenseScreen from '../../screens/TermsPolicies/Organizer/TermsLicense';
import TermsOrganizerPrivacityScreen from '../../screens/TermsPolicies/Organizer/TermsPrivacity';
import TermsOrganizerUseScreen from '../../screens/TermsPolicies/Organizer/TermsUse';

import NameRoutes from '../names';
import ScrollToTop from '../scrollToTop';
import SocialLinks from '../../screens/SocialLinks';



function AppRoutes() {
  return (

    <BrowserRouter>
      <Switch>

        <Route exact path={NameRoutes.LandingNoitadaScreen}>
          <ScrollToTop />
          <LandingNoitadaScreen />
        </Route>

        <Route exact path={NameRoutes.OrganizerAboutLandingScreen}>
          <ScrollToTop />
          <OrganizerAboutLandingScreen />
        </Route>

        <Route exact path={NameRoutes.OrganizerDetailsLandingScreen}>
          <ScrollToTop />
          <OrganizerDetailsLandingScreen />
        </Route>

        <Route exact path={NameRoutes.ProLandingScreen}>
          <ScrollToTop />
          <ProLandingScreen />
        </Route>

        {/* <Route exact path={NameRoutes.BlogScreen} component={BlogScreen} /> */}
        {/* <Route exact path={NameRoutes.AboutScreen} component={AboutScreen} /> */}
        {/* <Route exact path={NameRoutes.ABetterNightScreen} component={ABetterNightScreen} />
        <Route exact path={NameRoutes.CountriesScreen} component={CountriesScreen} /> */}

        {/* <Route exact path={NameRoutes.CoinsScreen} component={CoinsScreen} /> */}

        {/* <Route exact path={NameRoutes.DevelopersScreen} component={DevelopersScreen} /> */}

        {/* <Route exact path={NameRoutes.HelpCenterScreen} component={HelpCenterScreen} /> */}
        {/* <Route exact path={NameRoutes.HelpPartnerScreen} component={HelpPartnerScreen} /> */}
        {/* <Route exact path={NameRoutes.HelpUserScreen} component={HelpUserScreen} /> */}

        <Route exact path={NameRoutes.SocialLinksScreen}>
          <ScrollToTop />
          <SocialLinks />
        </Route>


        <Route exact path={NameRoutes.ContactScreen}>
          <ScrollToTop />
          <ContactScreen />
        </Route>

        <Route exact path={NameRoutes.PressScreen}>
          <ScrollToTop />
          <PressScreen />
        </Route>

        <Route exact path={NameRoutes.TermsPoliciesScreen}>
          <ScrollToTop />
          <TermsPoliciesScreen />
        </Route>

        <Route exact path={NameRoutes.TermsNoitadaLicenseScreen}>
          <ScrollToTop />
          <TermsNoitadaLicenseScreen />
        </Route>

        <Route exact path={NameRoutes.TermsNoitadaPrivacityScreen}>
          <ScrollToTop />
          <TermsNoitadaPrivacityScreen />
        </Route>

        <Route exact path={NameRoutes.TermsNoitadaUseScreen}>
          <ScrollToTop />
          <TermsNoitadaUseScreen />
        </Route>

        <Route exact path={NameRoutes.TermsNoitadaCookiesScreen}>
          <ScrollToTop />
          <TermsNoitadaCookiesScreen />
        </Route>

        <Route exact path={NameRoutes.TermsOrganizerLicenseScreen}>
          <ScrollToTop />
          <TermsOrganizerLicenseScreen />
        </Route>

        <Route exact path={NameRoutes.TermsOrganizerPrivacityScreen}>
          <ScrollToTop />
          <TermsOrganizerPrivacityScreen />
        </Route>

        <Route exact path={NameRoutes.TermsOrganizerUseScreen}>
          <ScrollToTop />
          <TermsOrganizerUseScreen />
        </Route>

        <Route exact path={NameRoutes.TermsOrganizerCookiesScreen}>
          <ScrollToTop />
          <TermsOrganizerCookiesScreen />
        </Route>

        <Route exact path={NameRoutes.TermsOrganizerDataScreen}>
          <ScrollToTop />
          <TermsOrganizerDataScreen />
        </Route>

        {/* <Route exact path={NameRoutes.JobsScreen} component={JobsScreen} /> */}

        {/* <Route exact path={NameRoutes.GetAppsLandingScreen} component={GetAppsLandingScreen} /> */}

        {/* <Route exact path={NameRoutes.FestivalHomeScreen} component={FestivalHomeScreen} />
        <Route exact path={NameRoutes.FestivalLineUpScreen} component={FestivalLineUpScreen} /> */}

        <Route exact path={NameRoutes.Error403Screen}>
          <ScrollToTop />
          <Error403Screen />
        </Route>

        <Route exact path={NameRoutes.Error404Screen}>
          <ScrollToTop />
          <Error404Screen />
        </Route>

        <Route exact path={NameRoutes.Error500Screen}>
          <ScrollToTop />
          <Error500Screen />
        </Route>

      </Switch>
    </BrowserRouter>

  );
}



export default AppRoutes;
