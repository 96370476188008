import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const TermsContentSegments = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: 24px;
  background-color: ${Colors.helpBackground};

  @media (max-width: 730px){
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: 530px){
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
`;


export const ContentColBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.2%;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: ${Sizes.cardRadius}px;
  transition: all 500ms ease;
`;

export const TitleItems = styled.h2`
  margin-bottom: 30px;
  color: ${Colors.black};
  font-size: 34px;
  font-weight: 300;
  white-space: nowrap;
 
  @media (max-width: 850px){
    font-size: 30px;
  }

  @media (max-width: 780px){
    font-size: 28px;
  }
  @media (max-width: 700px){
    font-size: 24px;
  }


  `;



export const WrapperTermsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 368px;
  width: 100%;
`;


export const ListBlockArticle = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: calc(100% - 8px);
  height: 65px;
  margin-bottom: 6px;

  background-color: ${Colors.white};
  border-color: rgba(0, 0, 0, .1);
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);

  transition: all 300ms ease;
  cursor: pointer;

  :hover {
    box-shadow: 0 4px 24px 1px rgb(0 0 0 / 20%);
  }

  @media (max-width: 925px){
    height: 85px;
  }

  @media (max-width: 700px){
   width: 200px;
  }

  
  @media (max-width: 530px){
    width: 350px;
  }
  
  @media (max-width: 410px){
    width: 300px;
  }

  @media (max-width: 350px){
    width: 250px;
  }
`;

export const ListBlockIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  margin-left: 12px;
  margin-right: 15px;
  background-color: ${Colors.primary};
  border-radius: 50px;
  

  `;

export const TextButtonArticle = styled.p`
  color: ${Colors.black};
  font-size: 15px;

  @media (max-width: 825px){
    font-size: 13px;
  }
  `;



export const FaqCategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Colors.black};

  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
`;

export const SectionTitleFaq = styled.h4`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.4px;
  text-align: center;
`;

export const BlackBorderButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
