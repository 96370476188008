import styled,
{
  css,
  keyframes,
} from 'styled-components';

import {
  GetPlatform,
} from '../../../config/plataform.config';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



const ButtonShake = keyframes`
  0% {
    transform: translate(0);
  }
  80% {
    transform: translateY(4px) rotate(0deg);
  }
  82% {
    transform: translateY(-4px) rotate(0deg);
  }
  84% {
    transform: translateY(4px) rotate(0deg);
  }
  86% {
    transform: translateY(-4px) rotate(0deg);
  }
  88% {
    transform: translateY(0) rotate(0deg);
  }
  90% {
    transform: translateY(-4px) rotate(0deg);
  }
  92% {
    transform: translateY(4px) rotate(0deg);
  }
  94% {
    transform: translateY(-4px) rotate(0deg);
  }
  96% {
    transform: translateY(4px) rotate(0deg);
  }
  98% {
    transform: translateY(-4px) rotate(0deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
`;



export const ButtonWhatsAppHover = css`
  :hover {
    background-color: ${Colors.primary};
    color: ${Colors.white};
    border-color: ${Colors.primary};
    box-shadow: 0 0 5px ${Colors.primary},
    0 0 5px ${Colors.primary},
    0 0 20px ${Colors.primary},
    0 0 20px ${Colors.primary};
  }
`;

export const WhatsAppContainerButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 25px;
  height: 45px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${Colors.primaryDark};
  border-color: ${Colors.grayDark};
  border-style: solid;
  border-width: 0.5px;
  border-radius: 32px;
  z-index: 9999;

  animation: ${ButtonShake} 5s infinite;
  transition: all 0.30s;

  ${GetPlatform() === 'WEB' && ButtonWhatsAppHover}
`;

export const WhatsAppButtonText = styled.p`
  margin-left: 8px;
  color: ${Colors.white};
  font-weight: 600;

  @media ${PlatformDevices.maxMobileM} {
    font-size: 14px;
    line-height: 21px;
  }

  @media ${PlatformDevices.minMobileM} {
    font-size: 15px;
    line-height: 22px;
  }

  @media ${PlatformDevices.minTablet} {
    font-size: 16px;
    line-height: 23px;
  }

  @media ${PlatformDevices.minTabletL} {
    font-size: 17px;
    line-height: 24px;
  }
`;
