// import {
//   StatusBar,
// } from 'react-native';



export const APP = {
  paddingContainer:                 16,
  paddingFlatList:                  12,
  imageDrawer:                      90,
  opacityOn:                        1,
  opacityOff:                       0.4,
};


export const ANIMATIONS = {
  loginTime:                        300,
  shimmerOpacity:                   0.3,
};


export const FOOTERS = {
  footerPadding:                    10,
};


export const MODALS = {
  borderModalsRadius:               15,
};


export const STATUSBAR = {
  STATUSBAR_ANDROID:                40, // StatusBar.currentHeight,
  STATUSBAR_IOS:                    20,
  STATUSBAR_IOS_IPHONE_X:           44,
};


export const TOGGLE = {
  TOOGLE_HEIGHT:                    40, // 42,
};



export const TOOLBARS = {
  TOOLBAR_WEB:                      64,
  TOOLBAR_ANDROID:                  56,
  TOOLBAR_IOS:                      44,

  SUBTOOLBAR_WEB:                   54,
  SUBTOOLBAR_ANDROID:               46, // 42,
  SUBTOOLBAR_IOS:                   34, // 32,


  BOTTOM_TOOLBAR_ANDROID:           55,
};



export const OTHERS = {
  BASE:                             16,
  FONT:                             16,
  ICON:                             16,
  OPACITY:                          0.8,
  BORDER_RADIUS:                    6,
  BORDER_WIDTH:                     0.8,


  disabledOpacity:                  0.35,
  shimmerOpacity:                   0.1,

  iconSize:                         35,
  menuContent:                      35,
  iconInputSize:                    27,
  iconAndroidSize:                  24,
  iconIosSize:                      22,

  iconFocusedSize:                  27,
  iconNavigationBottomSize:         19,


  buttonHeight:                     40,
  buttonTextSize:                   14,
  buttonRadius:                     5,
  buttonFullRadius:                 20,

  ButtonActiveOpacity:              0.5,
  CardActiveOpacity:                0.8,

  cardRadius:                       5,
  cardCarnivalRadius: 4,

  cardBorderLine:                   0.5,


  imageBannerSize:                  220,
  imageLogoSize:                    130, // Profile Edit Logo is 1X1


  TextTitle:                        16,
  TextSubtitle:                     14.5,
  SubtitleTextColumn:               15,

  TabTitle:                         15,
  iconSocialTreePages:              24,
  iconTermsPages:                   18,

  contentLandingMinHeight:          500,
  contentLandingPadding:            125,

      
  sectionTitle:                   60,
  sectionText:                    22,

  input:                          16,
  regular:                        14,
  medium:                         12,
  small:                          11,
  tiny:                           10,
  
  
  desktopPaddingVertical:         '40px',
  desktopPaddingHorizontal:       '50px',
  
  mobilePaddingVertical:          '25px',
  mobilePaddingHorizontal:        '20px',

  marginFromTitles:               '30px',
  
  

  toolbarHeight:                    60,

};



const Sizes = {
  ...APP,
  ...ANIMATIONS,
  ...FOOTERS,
  ...MODALS,
  ...STATUSBAR,
  ...TOGGLE,
  ...TOOLBARS,

  ...OTHERS,
};



export default Sizes;
