import styled,
{
  css,
} from 'styled-components';

import {
  GetPlatform,
} from '../../config/plataform.config';

import {
  Sizes,
  Colors,
} from '../../shared/constants';

import {
  PlatformDevices,
} from '../../shared/utils/platforms.utils';

import {
  TitleContents,
} from '../../styles/styled.layout';



export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: 20px;
  background: #006464;
  background: -webkit-linear-gradient(#006464, #00b0b0);
  background: linear-gradient(#006464, #00b0b0);

  @media ${PlatformDevices.maxTabletL} {
    padding-left: ${Sizes.mobilePaddingHorizontal};
    padding-right: ${Sizes.mobilePaddingHorizontal};
  }
`;

export const TitleTree = styled(TitleContents)`
  text-align: center;
`;



export const FooterSitemap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-bottom: 30px;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterColNormal = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;

  @media ${PlatformDevices.maxTabletM} {
    align-items: center;
    width: 100%;
  }

  @media ${PlatformDevices.minTabletM} {
    align-items: flex-start;
    width: 25%;
  }

  @media ${PlatformDevices.minLaptop} {
    align-items: flex-start;
    width: 20%;
  }
`;

export const FooterColSocial = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-top: 10px;
  align-items: center;

  @media ${PlatformDevices.maxTabletL} {
    width: 100%;
    min-width: 100%;
  }
`;

export const FooterLogo = styled.img`
  margin-top: 10px;
  margin-bottom: 5px;

  @media ${PlatformDevices.maxTabletM} {
    width: 180px;
    margin-bottom: 10px;
  }

  @media ${PlatformDevices.minTabletM} {
    width: 140px;
  }
`;


export const LinkFooter = styled.a`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 2px;
`;


export const FooterItemsTextHover = css`
  :hover {
    color: ${Colors.accent};
  }
`;

export const FooterItemsText = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
  transition: color 300ms ease;
  white-space: nowrap;

  @media ${PlatformDevices.maxTablet} {
    text-align: center;
  }

  ${GetPlatform() === 'WEB' && FooterItemsTextHover}
`;


export const FooterTitle = styled.h4`
  margin-top: 15px;
  margin-bottom: 0px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  line-height: 26px;

  @media ${PlatformDevices.maxTablet} {
    text-align: center;
  }
`;

export const FooterListView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;

  @media ${PlatformDevices.maxTablet} {
    align-items: center;
  }
`;



export const FooterSocialTitle = styled.h2`
  margin-bottom: 3px;
  color: ${Colors.white};
  font-size: 18px;
`;

export const ImageHeartStyle = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: middle;
  filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.35));
`;


export const FooterItemsSocialList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;


export const ButtonSocialHover = css`
  :hover {
    background-color: ${Colors.primaryDark};
    border-color: ${Colors.white};
    transition: all 0.20s;
  }
`;

export const ButtonSocial = styled.a`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 2.5px;
  border-radius: ${Sizes.cardRadius}px;
  border-style: solid;
  border-width: 0.5px;
  transition: all 0.20s;
  cursor: pointer;

  ${GetPlatform() === 'WEB' && ButtonSocialHover}
`;

export const ButtonFacebook = styled(ButtonSocial)`
  background-color: #284881;
  border-color: #284881;
`;

export const ButtonInstagram = styled(ButtonSocial)`
  background-color: #c32aa3;
  border-color: #c32aa3;
`;

export const ButtonTwitter = styled(ButtonSocial)`
  background-color: #1da1f2;
  border-color: #1da1f2;
`;

export const ButtonYoutube = styled(ButtonSocial)`
  background-color: #ff0000;
  border-color: #ff0000;
`;

export const ButtonThreads = styled(ButtonSocial)`
  background-color: #000000;
  border-color: #000000;
`;

export const ButtonTikTok = styled(ButtonSocial)`
  background-color: #000000;
  border-color: #000000;
`;

export const ButtonLinkedIn = styled(ButtonSocial)`
  background-color: #0e76a8;
  border-color: #0e76a8;
`;

export const ButtonEmail = styled(ButtonSocial)`
  background-color: #aa2200;
  border-color: #aa2200;
`;


export const ButtonTvHover = css`
  :hover {
    background-color: ${Colors.black};
    border-color: ${Colors.white};
  }
`;

export const FooterTv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

export const ButtonTv = styled(ButtonSocial)`
  background-color: ${Colors.black};
  border-color: ${Colors.black};

  ${GetPlatform() === 'WEB' && ButtonTvHover}
`;

export const LogoTvImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;
