import React from 'react';
import './styled.css';

import Error403Image from '../../shared/assets/images/error_403.png';



function Error403Screen() {
  return (

    <body className="body-container error-container error403-container">
      <div className="exception-body-container">
        <div className="exception-container">
          <img src="https://www.primefaces.org/apollo-react/assets/layout/images/logo-dark.png" alt="apollo-layout" />
          <h1>Error 403</h1>
          <p>
            Desculpe, você não tem acesso a esta página.
          </p>
          <a href="/" className="btn-to-click">Volte para o início</a>
        </div>
        <div className="exception-body-image">
          <img src={Error403Image} alt="apollo-layout" />
        </div>
      </div>
    </body>

  );
}



export default Error403Screen;
