export const APPSITES = {
  contaGlobalApp:                   'https://conta-dev.noitada.app',
  contaOrganizerRegister:           'https://conta-dev.noitada.app/register-user',

  noitadaSite:                      'https://dev.noitada.net',

  appNoitadaSite:                   'https://dev.noitada.app',

  organizerNoitadaSite:             'https://organizer-dev.noitada.app',

  backstageNoitadaSite:             'https://backstage-dev.noitada.app',

  storeNoitadaSite:                 'https://store-dev.noitada.app',
};



const Vars = {
  ...APPSITES,
};



export default Vars;
