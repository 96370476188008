import React from 'react';

import SocialButtons from '../../Containers/SocialButtons';

import {
  TermsFooterContainer,
  FooterText,
} from './styled';



export interface IProps {
  id?: string;
}



const FooterTerms: React.FC<IProps> = (props: any) => {
  return (

    <TermsFooterContainer
      id={props.id}>


      <SocialButtons />



      <FooterText>
        &copy; {new Date().getFullYear()} Noitada. Todos os direitos reservados.
      </FooterText>

    </TermsFooterContainer>

  );
};



export default FooterTerms;
